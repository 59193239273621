import React, { useEffect, useState } from "react";
import { formaterNumbertoPrice } from "../../../../utils/functions/reusableFunctions";
import Table from "../../../Table/Table";
import {
  getStockAsync,
  getStockReferenceIdAsync,
  patchStockSyncReference,
  postCost,
  setTransferQuantity,
  showNewCost,
  showReference,
  showReferenceFilter,
  showTransferQuantity,
} from "../../../../features/stockSlice";
import "./ReferenceInfo.css";
import Form from "../../../Form/Form";
import Error from "../../../Error/Error";
import { useDispatch, useSelector } from "react-redux";
import Decision from "../../../Decision/Decision";
import UpdateCosts from "../UpdateCosts";
import Loading from "../../../../utils/loading/Loading.js";

function ReferenceInfo({ setModal, closeModal }) {
  const [isLoading, setIsLoading] = useState(true);
  const lastColumns = ["Proveedor", "Costo Unitario", "Cantidad", "Fecha"];
  const costsColumns = ["Proveedor", "Código de Proveedor", "Costo Unitario"];
  const dispatch = useDispatch();
  const reference = useSelector(showReference);
  const refFilter = useSelector(showReferenceFilter);

  useEffect(()=>{
    setIsLoading(false);
  }, [reference])

  const transferReference = (quantity) => {
    console.log(quantity);
    if (!quantity || quantity === "0") {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (quantity > reference.balance) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.notStock} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro que deseas hacer el traslado de esta cantidad?" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => {
            transferReferenceFinal(quantity);
            closeModal("decision");
            closeModal("partial", "transfer");
          },
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  const transferReferenceFinal = (quantity) => {
    dispatch(
      patchStockSyncReference(reference.id, {
        balance: reference.balance - quantity,
      })
    );
    setTimeout(() => {
      const { searchValue, qualificationId, overstock, outstock } = refFilter;
      dispatch(
        getStockAsync(searchValue, qualificationId, overstock, outstock)
      );
    }, 200);
  };

  const updateCosts = (newCost) => {
    const { supplierId, cost, supplierCode } = newCost;
    if (!supplierId || !cost || cost === "0") {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      const newFinalCost = { supplierId, cost, referenceId: reference.id };
      if (supplierCode.trim() !== "") newFinalCost.supplierCode = supplierCode;
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro que deseas actualizar el costo de este proveedor?" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => {
            dispatch(postCost(newFinalCost));
            setTimeout(() => {
              dispatch(getStockReferenceIdAsync(reference.id));
            }, 200);
            closeModal("decision");
            closeModal("partial", "updateCosts");
          },
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  return Object.keys(reference).length === 0 || isLoading ? (
    <>
      <Loading />
    </>
  ) : (
    <>
      <h2 className="modal__title modal__title--alt">Información</h2>
      <div className="modal__info">
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Código:</span> {reference.id}
          </p>
          <p>
            <span>Referencia:</span> {reference.description}
          </p>
          <p>
            <span>Magnitud:</span>{" "}
            {`x${reference.magnitude} ${reference.unity?.unity}`}
          </p>
          <p>
            <span>Clasificación:</span> {reference.qualification.qualification}
          </p>
          <p>
            <span>Costo:</span> {formaterNumbertoPrice(reference.cost)}
          </p>
          <p>
            <span>Cantidad:</span> {reference.balance}
          </p>
        </div>
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Origen:</span> {reference.origin}
          </p>
          <p>
            <span>Peso por unidad:</span>{" "}
            {reference.unityWeight
              ? `${reference.unityWeight} kg`
              : "No Aplica"}
          </p>
          <p>
            <span>Costo Acumulado Promedio:</span>{" "}
            {formaterNumbertoPrice(reference.cpp)}
          </p>
          <p>
            <span>Límite Máximo:</span> {reference.maxLimit || "No Aplica"}
          </p>
          <p>
            <span>Límite Mínimo:</span> {reference.minLimit || "No Aplica"}
          </p>
        </div>
      </div>
      <button
        className="modal__btn modal__btn--middle"
        onClick={() => {
          setModal((prev) => ({
            ...prev,
            partial: {
              isActive: true,
              actionPrimary: "Aceptar",
              actionSecondary: "Cancelar",
              handlePrimary: transferReference,
              handleSecondary: () => {
                closeModal("partial", "transfer");
              },
              partialSelector: showTransferQuantity,
              // asyncSelector: showHistory,
              content: (
                <>
                  <h2 className="modal__title modal__title--small">
                    Ingresa la cantidad que deseas trasladar
                  </h2>
                  <Form>
                    <div className="input__container">
                      <p>
                        Cantidad <span>*</span>
                      </p>
                      <input
                        className="form__input"
                        type="number"
                        onChange={(e) =>
                          dispatch(setTransferQuantity(e.target.value))
                        }
                        required
                      />
                    </div>
                  </Form>
                </>
              ),
              onClose: () => closeModal("partial", "transfer"),
              errors: {
                ...prev.partial.errors,
                notStock:
                  "La cantidad que intentas trasladar supera lo que hay en inventario",
              },
            },
          }));
        }}
      >
        Hacer Traslado
      </button>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Historial</h2>
        <Table alt="table" columns={lastColumns}>
          <tbody className="table__body">
            {reference.incomes.map((item) => (
              <tr key={item.id}>
                <td>{item.supplier.fullName}</td>
                <td>{formaterNumbertoPrice(item.value)}</td>
                <td>{item.quantity}</td>
                <td>{item.date}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {reference.incomes.length === 0 && (
          <p className="modal__text modal__empty">No hay registros</p>
        )}
      </div>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Costos por Proveedor</h2>
        <Table alt="table" columns={costsColumns}>
          <tbody className="table__body">
            {reference.costs.map((item) => (
              <tr key={item.id}>
                <td>{item.supplier.fullName}</td>
                <td>{item.supplierCode}</td>
                <td>{formaterNumbertoPrice(item.cost)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {reference.costs.length === 0 && (
          <p className="modal__text modal__empty">No hay registros</p>
        )}
      </div>
      <button
        className="modal__btn modal__btn--middle"
        onClick={() => {
          setModal((prev) => ({
            ...prev,
            partial: {
              isActive: true,
              actionPrimary: "Guardar",
              actionSecondary: "Cancelar",
              handlePrimary: updateCosts,
              handleSecondary: () => {
                closeModal("partial", "updateCosts");
              },
              partialSelector: showNewCost,
              // asyncSelector: showHistory,
              content: <UpdateCosts setModal={setModal} />,
              onClose: () => closeModal("partial", "updateCosts"),
              errors: {
                ...prev.partial.errors,
              },
            },
          }));
        }}
      >
        Actualizar Costos
      </button>
    </>
  );
}

export default ReferenceInfo;
