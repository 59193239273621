import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from ".";

export default function Login() {

  const { authState, onLogin } = useAuth();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    await onLogin(data.email, data.password);
  };
  const handleClickRedirect = (route) => {
    navigate(route);
  };
  return (
    <div className="h-screen flex justify-center items-center">
      <section className="md:w-2/4">
        {authState.invalid && <p className="text-red-500 text-center">Tu sesión ha expirado, ingresa nuevamente</p>}
        <div className="justify-center p-10 rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center mb-2">
              <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold mb-5 text-green-700 text-center">
                Ingresa
              </h1>
            </div>
            <div className="mb-6">
              <h3 className="mb-2 text-1xl sm:text-3xl xl:text-4xl text-slate-700 font-semibold">
                Correo electrónico
              </h3>
              <input
                id="email"
                name="email"
                type="text"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                placeholder="example@example.com"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Su correo presenta errores, intente nuevamente",
                  },
                })}
              />
            </div>
            <div className="mb-6">
              <h3 className="mb-2 text-1xl sm:text-3xl xl:text-4xl text-slate-700 font-semibold">
                Contraseña
              </h3>
              <input
                name="password"
                type="password"
                className="form-control border-none bg-green-50 block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="••••••••"
                {...register("password", {
                  required: {
                    value: true,
                    message:
                      "Su contraseña presenta errores, intente nuevamente",
                  },
                })}
              />
              {authState.error && <p className="text-red-500 text-center">Tus credenciales son incorrectas o aún no has sido aprobado por el administrador</p>}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex justify-center text-center mt-1 rounded-full px-6 xl:px-32 py-2 bg-green-700 text-white font-semibold sm:text-2xl hover:bg-green-400 hover:shadow-lg focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out"
              >
                Entrar
              </button>
            </div>
            <div className="flex justify-end items-center mb-6 mt-6">
              <button
                onClick={() => handleClickRedirect("/recovery")}
                className="text-green-500 hover:text-gray"
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>

            <div className="flex justify-center">
              <div className="text-center lg:text-left flex items-center mb-6 ">
                <p className="text-md font-semibold mt-2 pt-1 mb-0">
                  ¿No tienes cuenta?
                </p>
                <button
                  onClick={() => handleClickRedirect("/register")}
                  className="inline-block ml-2 mt-auto text-green-500"
                >
                  Registrate
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
