import React from 'react'
import './HeaderButton.css';

function HeaderButton({children, handler}) {
  return (
    <button onClick={handler} type="button" className='headerButton'>
      {children}
    </button>
  )
}

export default HeaderButton
