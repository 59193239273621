import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from ".";
import React from "react";
export default function Register() {

  const navigate = useNavigate();
  const [signError, setSignError] = React.useState(false);
  const {authState, onSignUp} = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (info) => {
    const {names, lastNames, email, password, rePassword} = info;
    if(password !== rePassword) {
      setSignError(true);
    }else {
      const data = {
        firstName: names,
        lastName: lastNames,
        email,
        password
      }
      setSignError(false);
      onSignUp(data);
    }
  };
  const handleClickRedirect = (route) => {
    navigate(route);
  };
  return (
    <div className="flex justify-center items-center">
      <section className="md:w-2/4">
        <div className="justify-center p-10 rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center">
              <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold mb-5 text-green-700 text-center">
                Registrate
              </h1>
            </div>
            <div className="mb-6">
              <p className="mb-2 font-semibold text-2xl">Nombre</p>
              <input
                type="text"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="Escribe tu nombre"
                {...register("names", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                  pattern: {
                    value: /^[A-Za-záéíóú ]+$/i,
                    message: "Solo se permiten letras",
                  },
                  maxLength: {
                    value: 20,
                    message: "Ha excedido el límite de caracteres permitidos",
                  },
                })}
              />
              <p className="text-red-500">{errors.names?.message}</p>
            </div>

            <div className="mb-6">
              <h3 className="mb-2 font-semibold text-2xl">Apellido</h3>
              <input
                type="text"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="Escribe tu apellido"
                {...register("lastNames", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.lastNames?.message}</p>
            </div>
            <div className="mb-6">
              <p className="mb-2 font-semibold text-2xl">Correo electrónico</p>
              <input
                type="email"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="Escribe tu correo electrónico"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.email?.message}</p>
              {authState.signError && <p className="text-red-500">Este correo ya se encuentra registrado</p>}
            </div>

            <div className="mb-6">
              <h3 className="mb-2 font-semibold text-2xl">Contraseña</h3>
              <input
                type="password"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 "
                id="exampleFormControlInput2"
                placeholder="••••••••"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.password?.message}</p>
            </div>
            <div className="mb-6">
              <p className="mb-2 font-semibold text-2xl">
                Confirmar contraseña
              </p>
              <input
                type="password"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="••••••••"
                {...register("rePassword", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.rePassword?.message}</p>
              
              {signError && <p className="text-red-500">Las contraseñas no coinciden</p>}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex justify-center text-center mt-1 rounded-full px-6 xl:px-32 py-2 bg-green-700 text-white font-semibold sm:text-2xl hover:bg-green-400 hover:shadow-lg focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out"
              >
                Registrarme
              </button>
            </div>

            <div className="flex justify-center">
              <div className="text-center lg:text-left flex items-center mb-6 ">
                <p className="text-md font-semibold mt-2 pt-1 mb-0">
                  ¿Ya tienes cuenta?
                </p>
                <button
                  onClick={()=>handleClickRedirect("/login")}
                  className="inline-block ml-2 mt-auto text-green-500"
                >
                  Ingresa
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
