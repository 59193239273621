import React from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./HeaderSelect.css";

function HeaderSelect({ defaultOption, propertyName, options, setFilter }) {
  const changeFilter = (e) => {
    setFilter((prev) => ({
      ...prev,
      qualificationId: e.target.value !== "default" ? e.target.value : null,
    }));
  };

  return (
    <div className="headerSelect">
      <select type="select" onChange={changeFilter}>
        <option value="default">{defaultOption}</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option[propertyName]}
          </option>
        ))}
      </select>
      <div className="headerSelect__arrow">
        <RiArrowDropDownLine />
      </div>
    </div>
  );
}

export default HeaderSelect;
