import {MdPassword} from "react-icons/md"
import { useNavigate } from "react-router-dom";
export default function Password_created (){
    const navigate = useNavigate();
const handleClick= ()=>{
    navigate("/login")
}
    return (
        <div className="h-screen bg-green-100 flex justify-center items-center">
      <section className="">
            <div className="justify-center p-10 rounded-lg">
                <div className="flex justify-center items-center">
            <MdPassword className="text-green-700 text-9xl"/>
                </div>
                <div className="text-center">
                  <h1 className="mt-10 mb-5 text-3xl sm:text-5xl xl:text-7xl font-bold mb-5 text-slate-700">
                    Tu contraseña ha sido creada
                  </h1>
                  
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    onClick={()=>handleClick()}
                    className="flex justify-center text-center mt-5 rounded-full px-10 xl:px-20 py-2 bg-green-700 text-white font-semibold sm:text-2xl hover:bg-green-400 hover:shadow-lg focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out"
                  >
                    Probar
                  </button>
                </div>
            </div>
      </section>
    </div>
    )
}