import React from 'react'
import './Decision.css';

function Decision({ mssg }) {
    return (
        <>
            <h2 className='modal__title'>Antes de continuar</h2>
            <p className='modal__text'>{mssg}</p>
        </>
    )
}

export default Decision