import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_NAME, logOut } from "../components/auth";
import serverUri from "../config/config";
import axios from "axios";
const url = `${serverUri}/`;

const initialState = {
  suppliers: [],
  supplier: {},
  tempSupplier: {
    id: null,
    docTypeId: null,
    fullName: "",
    contact: null,
    email: "",
  },
  searchValue: null,
  docTypes: [],
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setTempSupplier: (state, action) => {
      state.tempSupplier = action.payload;
    },
    setDocTypes: (state, action) => {
      state.docTypes = action.payload;
    },
  },
});

export const {
  setSuppliers,
  setSupplier,
  setSearchValue,
  setTempSupplier,
  setDocTypes,
} = supplierSlice.actions;

export const getSuppliersAsync = (searchValue) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${url}supplier${searchValue ? `?searchValue=${searchValue}` : ""}`,
      {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      }
    );
    dispatch(setSuppliers(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const getSupplierAsync = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}supplier/${id}`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(setSupplier(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const setDocTypesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url}docType`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(setDocTypes(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const deleteSupplierAsync = (id) => async (dispatch) => {
  try {
    await axios.patch(
      `${url}supplier/${id}`,
      { fullName: "Proveedor Eliminado" },
      {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      }
    );
    dispatch(getSuppliersAsync());
  } catch (error) {
    console.log(error);
  }
};

export const postSupplierAsync = (data) => async (dispatch) => {
  try {
    await axios.post(`${url}supplier`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getSuppliersAsync());
  } catch (error) {
    console.log(error);
  }
};

export const patchSupplierAsync = (id, data) => async (dispatch) => {
  try {
    await axios.patch(`${url}supplier/${id}`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getSupplierAsync(id));
  } catch (error) {
    console.log(error);
  }
};

export const showSuppliers = (state) => state.supplier.suppliers;
export const showSupplier = (state) => state.supplier.supplier;
export const showTempSupplier = (state) => state.supplier.tempSupplier;
export const showDocTypes = (state) => state.supplier.docTypes;
export default supplierSlice.reducer;
