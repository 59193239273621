import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HeaderActions from "../HeaderActions/HeaderActions";
import SearchBar from "../SearchBar/SearchBar";
import Table from "../Table/Table";
import Modal from "../Modal/Modal";
import Error from "../Error/Error";
import Decision from "../Decision/Decision";
import {
  getSupplierAsync,
  getSuppliersAsync,
  setSearchValue,
  setTempSupplier,
  showSupplier,
  showSuppliers,
  showTempSupplier,
  setDocTypesAsync,
  postSupplierAsync,
  deleteSupplierAsync,
  patchSupplierAsync,
} from "../../features/supplierSlice";
import NewSupplier from "./NewSupplier";
import { defaultModalState, supplierDefault } from "./defaultValues";
import HeaderButton from "../HeaderActions/HeaderButton/HeaderButton";
import SupplierInfo from "./SupplierInfo";

function SupplierView() {
  const dispatch = useDispatch();

  //Selectors
  const suppliers = useSelector(showSuppliers);

  //Filter States
  const [filter, setFilter] = useState({ searchValue: "" });

  //Modal Controller State

  const [modal, setModal] = useState({
    partial: defaultModalState,
    accum: defaultModalState,
    crudForm: defaultModalState,
    decision: defaultModalState,
  });

  useEffect(() => {
    dispatch(getSuppliersAsync());
    dispatch(setDocTypesAsync());
  }, [dispatch]);

  const saveSupplier = (supplier, suppliers) => {
    dispatch(getSuppliersAsync());
    setTimeout(() => {
      const { id, docTypeId, fullName, contact } = supplier;
      if (!id || !docTypeId || !fullName || !contact) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.required} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else if (suppliers.some((item) => item.id === supplier.id)) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.alreadyExists} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else {
        const { searchValue } = filter;
        const { email, ...finalSup } = supplier;
        if (email) finalSup.email = email;
        dispatch(postSupplierAsync(finalSup));
        setTimeout(() => {
          dispatch(getSuppliersAsync(searchValue));
        }, 300);
        closeModal("crudForm");
      }
    }, 100);
  };

  const openEditSupplier = (supplier) => {
    dispatch(setTempSupplier(supplier));
    setModal((prev) => ({
      ...prev,
      crudForm: {
        isActive: true,
        actionPrimary: "Guardar",
        actionSecondary: "Cancelar",
        handlePrimary: (newSupp, suppliers) => updateSupplier(newSupp, suppliers, supplier),
        handleSecondary: () => closeModal("crudForm", "newSupplier"),
        partialSelector: showTempSupplier,
        asyncSelector: showSuppliers,
        content: <NewSupplier isNew={false}/>,
        onClose: () => closeModal("crudForm", "newSupplier"),
        errors: {
          ...prev.crudForm.errors,
          alreadyExists: "Ya existe un proveedor con esta identificación",
        },
      },
    }));
  };

  const updateSupplier = (newSupp, suppliers, supplier) => {
    dispatch(getSuppliersAsync());
    setTimeout(() => {
      const { docTypeId, fullName, contact } = newSupp;
      if (!docTypeId || !fullName || !contact) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.required} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else if (suppliers.some((item) => item.fullName === fullName) && fullName !== supplier.fullName) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.alreadyExists} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else {
        const { searchValue } = filter;
        const { id, email, docType, ...finalSup } = newSupp;
        if (email) finalSup.email = email;
        dispatch(patchSupplierAsync(supplier.id, finalSup));
        setTimeout(() => {
          dispatch(getSuppliersAsync(searchValue));
        }, 300);
        closeModal("crudForm");
      }
    }, 100);
  };

  const openDeleteSupplier = (id) => {
    setModal((prev) => ({
      ...prev,
      decision: {
        isActive: true,
        content: (
          <Decision mssg="¿Estás seguro que deseas eliminar este proveedor?" />
        ),
        actionPrimary: "Sí",
        handlePrimary: () => {
          dispatch(deleteSupplierAsync(id));
          const { searchValue } = filter;
          setTimeout(() => {
            dispatch(getSuppliersAsync(searchValue));
          }, 200);
          closeModal("decision");
          closeModal("crudForm");
          closeModal("accum");
        },
        actionSecondary: "No",
        handleSecondary: () => closeModal("decision"),
        onClose: () => closeModal("decision"),
      },
    }));
  };

  const closeModal = (type, process = null) => {
    setModal((prev) => ({
      ...prev,
      [type]: defaultModalState,
    }));
  };

  //Inicialización de variables aux para tablas
  let columns = [];

  //Despachadores de filtros cuando detectan cambios
  useEffect(() => {
    const { searchValue } = filter;
    dispatch(setSearchValue(searchValue));
    dispatch(getSuppliersAsync(searchValue));
  }, [filter, dispatch]);

  //Switch de renderizado dependiendo de la sub ruta

  columns = ["Identificación", "Nombre", "Contacto", "Correo Electrónico"];
  return (
    <div className="viewSection">
      <HeaderActions>
        {/* type? -> Cuando ejecutar el handleEvent
            handleEvent -> Función capturadora del valor
            property? -> En caso de que la capturadora deba setear determinada propiedad de un objeto
            reset -> Estado que al cambiar resetea el valor 
            location? -> Ubicación, form si es en formulario*/}
        <SearchBar
          type="submit"
          placeholder="ID o nombre de proveedor"
          handleEvent={setFilter}
          property="searchValue"
        />
        <HeaderButton
          handler={() => {
            dispatch(getSuppliersAsync());
            dispatch(setTempSupplier(supplierDefault));
            setModal((prev) => ({
              ...prev,
              crudForm: {
                isActive: true,
                actionPrimary: "Guardar",
                actionSecondary: "Cancelar",
                handlePrimary: saveSupplier,
                handleSecondary: () => closeModal("crudForm", "newSupplier"),
                partialSelector: showTempSupplier,
                asyncSelector: showSuppliers,
                content: <NewSupplier />,
                onClose: () => closeModal("crudForm", "newSupplier"),
                errors: {
                  ...prev.crudForm.errors,
                  alreadyExists:
                    "Ya existe un proveedor con esta identificación",
                },
              },
            }));
          }}
        >
          Añadir Proveedor
        </HeaderButton>
      </HeaderActions>
      <Table columns={columns}>
        <tbody className="table__body">
          {suppliers.map((supp) => (
            <tr
              key={supp.id}
              onClick={() => {
                dispatch(getSupplierAsync(supp.id));
                setTimeout(() => {
                  setModal((prev) => ({
                    ...prev,
                    accum: {
                      isActive: true,
                      actionPrimary: "Editar",
                      actionSecondary: "Eliminar",
                      handlePrimary: openEditSupplier,
                      handleSecondary: () => openDeleteSupplier(supp.id),
                      partialSelector: showSupplier,
                      // asyncSelector: showHistory,
                      content: <SupplierInfo />,
                      onClose: () => closeModal("accum", "supplier"),
                      errors: {
                        ...prev.accum.errors,
                      },
                    },
                  }));
                }, 300);
              }}
            >
              <td>{supp.id}</td>
              <td>{supp.fullName}</td>
              <td>{supp.contact}</td>
              <td>{supp.email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {suppliers.length === 0 && (
        <p className="modal__text modal__empty">No hay registros</p>
      )}
      {modal.partial.isActive && (
        <Modal
          actionPrimary={modal.partial.actionPrimary}
          actionSecondary={modal.partial.actionSecondary}
          handlePrimary={modal.partial.handlePrimary}
          handleSecondary={modal.partial.handleSecondary}
          onClose={modal.partial.onClose}
          partialSelector={modal.partial.partialSelector}
          asyncSelector={modal.partial.asyncSelector}
        >
          {modal.partial.content}
        </Modal>
      )}
      {modal.accum.isActive && (
        <Modal
          actionPrimary={modal.accum.actionPrimary}
          actionSecondary={modal.accum.actionSecondary}
          handlePrimary={modal.accum.handlePrimary}
          handleSecondary={modal.accum.handleSecondary}
          onClose={modal.accum.onClose}
          partialSelector={modal.accum.partialSelector}
          asyncSelector={modal.accum.asyncSelector}
        >
          {modal.accum.content}
        </Modal>
      )}
      {modal.crudForm.isActive && (
        <Modal
          actionPrimary={modal.crudForm.actionPrimary}
          actionSecondary={modal.crudForm.actionSecondary}
          handlePrimary={modal.crudForm.handlePrimary}
          handleSecondary={modal.crudForm.handleSecondary}
          onClose={modal.crudForm.onClose}
          partialSelector={modal.crudForm.partialSelector}
          asyncSelector={modal.crudForm.asyncSelector}
        >
          {modal.crudForm.content}
        </Modal>
      )}
      {modal.decision.isActive && (
        <Modal
          actionPrimary={modal.decision.actionPrimary}
          actionSecondary={modal.decision.actionSecondary}
          handlePrimary={modal.decision.handlePrimary}
          handleSecondary={modal.decision.handleSecondary}
          onClose={modal.decision.onClose}
        >
          {modal.decision.content}
        </Modal>
      )}
    </div>
  );
}

export default SupplierView;
