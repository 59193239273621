import React from 'react'
import './Table.css';

function Table({columns, children, alt}) {
  return (
    <div className='tableContainer'>
      <table className={alt ? 'table table--form': 'table'}>
        <thead className='table__header'>
            <tr>
                {columns.map(column => <th key={column}>{column}</th>)}
            </tr>
        </thead>
        {children}
      </table>
    </div>
  )
}

export default Table
