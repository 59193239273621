import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_NAME, logOut } from "../components/auth";
import serverUri from "../config/config";
const url = `${serverUri}/`;

const axios = require("axios");
const initialState = {
  references: [],
  reference: {},
  editReference: {
    description: "",
    magnitude:null,
    unityId: null,
    qualificationId: null,
    origin: null,
    minLimit: null,
    maxLimit: null,
    unityWeight: null,
  },
  units: [],
  qualification: [],
  productType: [],
  invoices: [],
  auxInvoices: [],
  ref: [],
  suppliers: [],
  docTypes: [],
  cost: [],
  history: [],
  typesView: "references",
  infoTable: [],
  currentInfoNewEntry: [],
  newRef: {
    description: "",
    magnitude: null,
    unityId: null,
    qualificationId: null,
    origin: null,
    minLimit: null,
    maxLimit: null,
    unityWeight: null,
  },
  newMagnit: 0,
  newSup: {
    id: null,
    docTypeId: null,
    fullName: "",
    contact: null,
    email: "",
  },
  newCost: {
    supplierId: null,
    supplierCode: "",
    referenceId: null,
    cost: "",
  },
  newInput: {
    id: "",
    quotation: "",
    userId: null,
    supplierId: null,
    supplier: null,
    incomes: [],
  },
  newIncome: {
    reentry: false,
    supplierCode: "",
    inputId: null,
    referenceId: null,
    quantity: "",
    value: "",
    supplierId: null,
    referenceText: null,
    referenceMainId: null,
  },
  newInvoice: {
    id: "",
    userId: null,
    supplierId: null,
    supplier: null,
    inputs: [],
  },
  inputId: null,
  transferQuantity: null,
  inputChoice: null,
  invoice: null,
  newMagnitude: null,
  referenceFilter: {
    searchValue: null,
    qualificationId: null,
    overstock: false,
    outstock: false,
  },
  inputFilter: {
    searchValue: null,
    date: null,
  },
  invoiceFilter: {
    searchValue: null,
    date: null,
  },
};

export const stockSlide = createSlice({
  name: "stock",
  initialState,
  reducers: {
    getStock: (state, action) => {
      state.references = action.payload;
      state.infoTable = action.payload;
    },
    getStockReference: (state, action) => {
      state.reference = action.payload;
    },
    getUnits: (state, action) => {
      state.units = action.payload;
    },
    getQualification: (state, action) => {
      state.qualification = action.payload;
    },
    getProductType: (state, action) => {
      state.productType = action.payload;
    },
    getInvoices: (state, action) => {
      state.invoices = action.payload;
      state.infoTable = action.payload;
    },
    getReference: (state, action) => {
      state.ref = action.payload;
    },
    getSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    getDocTypes: (state, action) => {
      state.docTypes = action.payload;
    },
    getCost: (state, action) => {
      state.cost = action.payload;
    },
    getStockHistory: (state, action) => {
      state.history = action.payload;
      state.infoTable = action.payload;
    },
    changeCurrentInfoNewEntry: (state, action) => {
      state.currentInfoNewEntry = [
        ...state.currentInfoNewEntry,
        action.payload,
      ];
    },
    changeStatusTypesView: (state, action) => {
      state.typesView = action.payload;
    },
    changeNewRef: (state, action) => {
      state.newRef = action.payload;
    },
    changeNewMagnit: (state, action) => {
      state.newMagnit = action.payload;
    },
    changeNewSup: (state, action) => {
      state.newSup = action.payload;
    },
    changeInfoTable: (state, action) => {
      state.infoTable = action.payload;
    },
    setNewInput: (state, action) => {
      state.newInput = action.payload;
    },
    setNewIncome: (state, action) => {
      state.newIncome = action.payload;
    },
    setInputId: (state, action) => {
      state.inputId = action.payload;
    },
    setTransferQuantity: (state, action) => {
      state.transferQuantity = action.payload;
    },
    setNewCost: (state, action) => {
      state.newCost = action.payload;
    },
    setEditReference: (state, action) => {
      state.editReference = action.payload;
    },
    setNewInvoice: (state, action) => {
      state.newInvoice = action.payload;
    },
    setAuxInvoices: (state, action) => {
      state.auxInvoices = action.payload;
    },
    setInputChoice: (state, action) => {
      state.inputChoice = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setNewMagnitude: (state, action) => {
      state.newMagnitude = action.payload;
    },
    setReferenceFilter: (state, action) => {
      state.referenceFilter = action.payload;
    },
    setInputFilter: (state, action) => {
      state.inputFilter = action.payload;
    },
    setInvoiceFilter: (state, action) => {
      state.invoiceFilter = action.payload;
    },
  },
});
//References --> Referencias

export const getStockAsync =
  (
    filter = null,
    qualificationId = null,
    overstock = false,
    outstock = false
  ) =>
  async (dispatch) => {
    try {
      let criteria = [];
      if (filter) criteria.push(`searchValue=${filter}`);
      if (qualificationId) criteria.push(`qualificationId=${qualificationId}`);
      if (overstock) criteria.push(`overstock=true`);
      if (outstock) criteria.push(`outstock=true`);

      const response = await axios.get(
        `${url}stock/references${
          criteria.length > 0 ? "?" + criteria.join("&") : ""
        }`,
        {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        }
      );
      dispatch(getStock(response.data));
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };

export const getStockIdAsync = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}reference?id=${id}&input=true`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getStockReference(response.data[0]));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const getStockReferenceIdAsync = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}stock/references?id=${id}`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getStockReference(response.data[0]));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const patchStockSyncReference = (id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(`${url}reference/${id}`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    const stockResponse = await axios.get(
      `${url}stock/references?id=${response.data.id}`,
      {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      }
    );
    dispatch(getStockReference(stockResponse.data[0]));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const deleteStockSyncReference = (id) => {
  try {
    axios
      .delete(`${url}stock/${id}`, {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      })
      .then(() => alert("Data successfully deleted"));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const postCost = (data) => async () => {
  try {
    await axios.post(`${url}cost`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
// ------- -------- -----
//Entries Record --> Historial de entradas
export const getStockHistoryAsync =
  (filter = "", id = null, date = null) =>
  async (dispatch) => {
    try {
      let criteria = [];
      if (id) criteria.push(`id=${id}`);
      if (filter) criteria.push(`searchValue=${filter}`);
      if (date) criteria.push(`date=${date}`);
      const response = await axios.get(
        `${url}stock/history${
          criteria.length > 0 ? "?" + criteria.join("&") : ""
        }`,
        {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        }
      );
      dispatch(getStockHistory(response.data));
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };

// ------- -------- -----
//New Entries --> Nueva entrada
//falta el get de reference y el como aplicar los filtros a dicha ruta
export const getReferenceAsync =
  (searchValue = "", id = null) =>
  async (dispatch) => {
    try {
      let criteria = [];
      criteria.push(`input=true`);
      if (id) criteria.push(`id=${id}`);
      if (searchValue) criteria.push(`searchValue=${searchValue}`);
      const response = await axios.get(
        `${url}reference${criteria.length > 0 ? "?" + criteria.join("&") : ""}`,
        {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        }
      );
      dispatch(getReference(response.data));
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };
export const getUnityAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url}unity`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getUnits(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const getCurrentInfoNewEntry = (data) => (dispatch) => {
  try {
    dispatch(changeCurrentInfoNewEntry(data));
  } catch (err) {
    throw new Error();
  }
};
export const getQualificationAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url}qualification`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getQualification(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const getProductTypeAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url}productType`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getProductType(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const postReference = (data) => async () => {
  //Hay que aclarar la parte de crear magnitud y referencia
  try {
    await axios.post(`${url}reference`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const getSuppliersAsync =
  (searchValue = "") =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${url}supplier?searchValue=${searchValue}`,
        {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        }
      );
      dispatch(getSuppliers(response.data));
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };
export const getDocTypeAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${url}docType`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getDocTypes(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const postSuppliersAsync = (data) => async () => {
  try {
    await axios.post(`${url}supplier`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    throw new Error(err);
  }
};
export const getCostAsync =
  (referenceId = "", supplierId = "") =>
  async (dispatch) => {
    try {
      if (referenceId.length && supplierId.length) {
        const response = await axios.get(
          `${url}cost?referenceId=${referenceId}&supplierId=${supplierId}`,
          {
            headers: {
              Authorization: `bearer ${
                JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
              }`,
            },
          }
        );
        dispatch(getCost(response.data));
      } else if (supplierId.length) {
        const response = await axios.get(
          `${url}cost?supplierId=${supplierId}`,
          {
            headers: {
              Authorization: `bearer ${
                JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
              }`,
            },
          }
        );
        dispatch(getCost(response.data));
      } else if (referenceId.length) {
        const response = await axios.get(
          `${url}cost?referenceId=${referenceId}`,
          {
            headers: {
              Authorization: `bearer ${
                JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
              }`,
            },
          }
        );

        dispatch(getCost(response.data));
      } else {
        const response = await axios.get(`${url}cost`, {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        });
        dispatch(getCost(response.data));
      }
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };
export const postInput = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${url}input`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    const { id } = response.data;
    dispatch(setInputId(id));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const postIncome = (data) => async (dispatch) => {
  //Leva cierta logica extra dependiendo de lo que llega por data
  try {
    await axios.post(`${url}income`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const changeInfoNewRef = (data) => (dispatch) => {
  try {
    dispatch(changeNewRef(data));
  } catch (error) {
    throw new Error();
  }
};
export const changeInfoNewMagnit = (data) => (dispatch) => {
  try {
    dispatch(changeNewMagnit(data));
  } catch (error) {
    throw new Error();
  }
};
// ------- -------- -----
//Invoices --> Facturas
export const getInvoicesAsync =
  (filter = "", date = null, id = null, aux = false) =>
  async (dispatch) => {
    try {
      let criteria = [];
      if (filter) criteria.push(`searchValue=${filter}`);
      if (date) criteria.push(`date=${date}`);
      if (id) criteria.push(`id=${id}`);
      const response = await axios.get(
        `${url}invoice${criteria.length > 0 ? "?" + criteria.join("&") : ""}`,
        {
          headers: {
            Authorization: `bearer ${
              JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
            }`,
          },
        }
      );
      dispatch(
        aux ? setAuxInvoices(response.data) : getInvoices(response.data)
      );
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  };

export const getInvoicesIdAsync = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}invoice?id=${id}`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(setInvoice(response.data[0]));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const postInvoicesAsync = (data) => async () => {
  try {
    await axios.post(`${url}invoice`, data, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const updateInvoicesAsync = (id, data) => {
  try {
    axios
      .patch(`${url}invoice/${id}`, data, {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      })
      .then(() => alert("Data successfully updated"));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};
export const deleteInvoicesAsync = (id) => async () => {
  try {
    await axios.delete(`${url}invoice/${id}`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const {
  getStock,
  getStockReference,
  getUnits,
  getQualification,
  getProductType,
  getInvoices,
  getReference,
  getSuppliers,
  getDocTypes,
  getCost,
  getStockHistory,
  changeStatusTypesView,
  changeCurrentInfoNewEntry,
  changeNewRef,
  changeNewMagnit,
  changeNewSup,
  setNewInput,
  setNewIncome,
  setInputId,
  setTransferQuantity,
  setNewCost,
  setEditReference,
  setNewInvoice,
  setAuxInvoices,
  setInputChoice,
  setInvoice,
  setNewMagnitude,
  setReferenceFilter,
  setInputFilter,
  setInvoiceFilter
} = stockSlide.actions;
export const showReferences = (state) => state.stock.references;
export const showReference = (state) => state.stock.reference;
export const showUnits = (state) => state.stock.units;
export const showQualification = (state) => state.stock.qualification;
export const showProductType = (state) => state.stock.productType;
export const showInvoices = (state) => state.stock.invoices;
export const showRef = (state) => state.stock.ref;
export const showSuppliers = (state) => state.stock.suppliers;
export const showDocTypes = (state) => state.stock.docTypes;
export const showCost = (state) => state.stock.cost;
export const showHistory = (state) => state.stock.history;
export const showTypesView = (state) => state.stock.typesView;
export const showInfoTable = (state) => state.stock.infoTable;
export const showNewRef = (state) => state.stock.newRef;
export const showNewMagnit = (state) => state.stock.newMagnit;
export const showNewSup = (state) => state.stock.newSup;
export const showCurrentInfoNewEntry = (state) =>
  state.stock.currentInfoNewEntry;
export const showNewInput = (state) => state.stock.newInput;
export const showNewIncome = (state) => state.stock.newIncome;
export const showInputId = (state) => state.stock.inputId;
export const showTransferQuantity = (state) => state.stock.transferQuantity;
export const showNewCost = (state) => state.stock.newCost;
export const showEditReference = (state) => state.stock.editReference;
export const showNewInvoice = (state) => state.stock.newInvoice;
export const showAuxInvoices = (state) => state.stock.auxInvoices;
export const showInputChoice = (state) => state.stock.inputChoice;
export const showInvoice = (state) => state.stock.invoice;
export const showNewMagnitude = (state) => state.stock.newMagnitude;
export const showReferenceFilter = (state) => state.stock.referenceFilter;
export const showInputFilter = (state) => state.stock.inputFilter;
export const showInvoiceFilter = (state) => state.stock.invoiceFilter;
export default stockSlide.reducer;
