import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNewSup, showDocTypes, showNewSup } from "../../features/stockSlice";
import Form from "../Form/Form";
import FormDrop from "../Form/FormDrop/FormDrop";

function NewSupplier() {
  const dispatch = useDispatch();
  const newSup = useSelector(showNewSup);
  const [docType, setDocType] = useState("Elige un tipo de documento");
  const docTypes = useSelector(showDocTypes);

  const setProps = (prop) => (value) => {
    dispatch(
      changeNewSup({
        ...newSup,
        [prop]: value,
      })
    );
  };

  return (
    <>
      <h2 className="modal__title">Nuevo Proveedor</h2>
      <Form row="true">
        <div className="input__container">
          <p>
            ID <span>*</span>
          </p>
          <input
            type="text"
            className="form__input"
            value={newSup.id}
            onChange={(e) => setProps("id")(e.target.value)}
          />
        </div>
        <FormDrop
          title="Tipo de Documento"
          search={false}
          placeholder={docType}
          handler={setProps("docTypeId")}
          placeholderHandler={setDocType}
          data={docTypes}
          dataProps={["description"]}
          dataConector={[""]}
        ></FormDrop>
        <div className="input__container">
          <p>
            Nombre <span>*</span>
          </p>
          <input
            type="text"
            className="form__input"
            value={newSup.fullName}
            onChange={(e) => setProps("fullName")(e.target.value)}
          />
        </div>
        <div className="input__container">
          <p>
            Contacto <span>*</span>
          </p>
          <input
            type="number"
            className="form__input"
            value={newSup.contact}
            onChange={(e) => setProps("contact")(e.target.value)}
          />
        </div>
        <div className="input__container">
          <p>Correo</p>
          <input
            type="text"
            className="form__input"
            value={newSup.email}
            onChange={(e) => setProps("email")(e.target.value)}
          />
        </div>
      </Form>
    </>
  );
}

export default NewSupplier;
