import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../features/dashboardSlice";
import stockSlice from "../features/stockSlice";
import helpSlice from "../features/helpSlice";
import supplierSlice from "../features/supplierSlice";

//
export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    stock: stockSlice,
    help: helpSlice,
    supplier: supplierSlice
  },
});
  