import React, { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { MdOutlinePersonOutline } from "react-icons/md";
import ModalRol from "../ModalRol/ModalRol";
import { getDashboardNewsAsync, modifyUserRole } from "../../../features/dashboardSlice";
import { useDispatch } from "react-redux";



const ModalNotis = ({ data, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [showModal1, setShowModal1] = useState(false);
  const user = data;

  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const openModalRol = (e) => {
    setShowModal(false);

    const openModal1 = () => {
      setShowModal1((prev) => !prev);
    };
    openModal1();
  };

  const handleReject = () => {
    dispatch(modifyUserRole("delete", user.id));
    setTimeout(() => {
      dispatch(getDashboardNewsAsync());
    }, 800)
    closeModal();
  }

  const closeModal = (e) => {
    setShowModal(false);
  };

  const handleAccept = () => {
    openModalRol()
  }

  return (
    <>
      {showModal ? (
        <ContainerModal ref={modalRef}>
          <animated.div style={animation}>
            <WindowNoti showModal={showModal}>
              <Content>
                <button onClick={closeModal}>
                  <ButtonClose />
                </button>
                <ContentContainer>
                  <TitleContainer>
                    <PersonIcon />
                    <H1>Nuevo Usuario</H1>
                  </TitleContainer>
                  <DescriptionContainer>
                    <H2>{user.firstName + " " + user.lastName}</H2>
                    <Mail>{user.email}</Mail>
                  </DescriptionContainer>
                  <ButtonContainer>
                    <Button onClick={handleAccept}>Aceptar</Button>
                    <Button onClick={handleReject}>Rechazar</Button>
                  </ButtonContainer>
                </ContentContainer>
              </Content>
            </WindowNoti>
          </animated.div>
        </ContainerModal>
      ) : null}
      <ModalRol user={user} showModal1={showModal1} setShowModal1={setShowModal1} />
    </>
  );
};

export default ModalNotis;

const ContainerModal = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 2;
  @media screen and (max-width: 1024px) {
    height: 1113px;
  }
  @media screen and (max-width: 480px) {
    height: 100vh;
  }
`;

const WindowNoti = styled.div`
  background: transparent;
  display: flex;
  z-index: 10;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    overflow-y: none;
    overflow-y: none;
  }
`;

const Content = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 323px;
  height: 182px;
  padding: 10px 20px;
  justify-content: center;
  border-radius: 30px;
`;

const ButtonClose = styled(IoMdClose)`
  position: absolute;
  font-size: 30px;
  top: 0;
  right: 0;
  margin-top: 19px;
  margin-right: 19px;
`;

const ContentContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  color: #258d00;
`;

const PersonIcon = styled(MdOutlinePersonOutline)`
  font-size: 28px;
`;

const H1 = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 6px;
  margin-top: 9px;
`;

const H2 = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Mail = styled.p`
  font-size: 13px;
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Button = styled.button`
  width: 100%;
  background-color: #258d00;
  margin: 9px 9px 0px 9px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
`;