import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { CgMenu } from "react-icons/cg";
import { TbHome } from "react-icons/tb";
import {
  MdOutlineInventory,
  MdOutlineLocalShipping,
  MdOutlinePersonOutline,
  MdLogout,
} from "react-icons/md";
import { FaRegHandshake, FaCubes } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { useAuth } from "../auth";

/* Asset de Prueba */
import Image from "../../assets/user2.png";
import { useDispatch } from "react-redux";
import { changeStatusTypesView } from "../../features/stockSlice";

const NavBar = ({ toggle, Users }) => {
  const dispatch = useDispatch();
  const { authState, onLogout } = useAuth();
  const [infoUser, setInfoUser] = useState(false);
  let role;
  switch (authState.user.rol) {
    case "admin":
      role = "Administrador";
      break;
    case "manager":
      role = "Gestor";
      break;
    case "assistance":
      role = "Asistente";
      break;
    default:
      role = "user";
      break;
  }
  const seeInfo = () => {
    setInfoUser((prev) => !prev);
  };

  const handleClickStock = () => {
    dispatch(changeStatusTypesView("references"));
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo infoUser={infoUser}>
          <img alt="" src={Image} onClick={seeInfo} />
          {infoUser === true && (
            <InfoContainer>
              <UserInformation>
                <NameUser>{`${authState.user.firstName} ${authState.user.lastName}`}</NameUser>
                <RolUser>{role}</RolUser>
              </UserInformation>
            </InfoContainer>
          )}
        </NavLogo>
        <MobileIcons onClick={toggle}>
          <Menu />
        </MobileIcons>
        <NavMenu>
          <NavItem>
            <NavLinks to="/dashboard" enable>
              <Home />
              <p>Home</p>
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks onClick={handleClickStock} to="/stock/references" enable>
              <Inventory />
              <p>Inventario</p>
            </NavLinks>
          </NavItem>
          {/* <NavItem>
            <NavLinks to="Bye">
              <Cubes />
              <p>APU's</p>
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="Form">
              <Car />
              <p>Traslados</p>
            </NavLinks>
          </NavItem> */}
          <NavItem>
            <NavLinks to="/suppliers" enable>
              <Hands />
              <p>Proveedores</p>
            </NavLinks>
          </NavItem>
          {/* <NavItem>
            <NavLinks to="Form">
              <People />
              <p>Clientes</p>
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="Form">
              <Person />
              <p>Usuarios</p>
            </NavLinks>
          </NavItem> */}
        </NavMenu>
        <NavMenu2>
          <NavItem>
            <NavLinks2 onClick={() => onLogout(false)}>
              <Logout />
              <p>Salir</p>
            </NavLinks2>
          </NavItem>
        </NavMenu2>
      </NavbarContainer>
    </Nav>
  );
};

export default NavBar;

const Nav = styled.nav`
  background: transparent;  
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 20;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 77px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 0;
    height: 58px;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80px;
  z-index: 20;
  height: 100%;
  background-color: #fff;
  max-width: 1100px;
  border-radius: 15px;
  flex-direction: column;
  text-align: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const NavLogo = styled.div`
  display: flex;
  position: relative;
  align-items: start;
  list-style: none;
  text-align: center;
  flex-direction: column;
  padding: 10px 10px;
  border-radius: 20px;
  width: 100%;
  height: 77px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 20;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }

  @media screen and (max-width: 480px) {
    height: 58px;
  }

  ${(props) => {
    switch (props.infoUser) {
      case true:
        return css`
          transition: 0.3s;
          width: 349px;
          background-color: #fff;
          display: flex;
          flex-direction: row;
          overflow-x: hidden;
          box-shadow: 0px 0.757576px 6.81818px rgba(0, 0, 0, 0.25);

          @media screen and (max-width: 1024px) {
            box-shadow: none;
          }

          @media screen and (max-width: 480px) {
            width: 300px;
          }
        `;
      default:
        return css`
          transition: 0.3s;
          background-color: #fff;
        `;
    }
  }}

  img {
    position: absolute;
    background-color: #234343;
    width: 57px;
    height: 57px;
    border-radius: 50px;
    object-fit: cover;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 480px) {
      width: 38px;
      height: 38px;
    }
  }
`;

const MobileIcons = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    justify-content: center;
    text-align: center;
    z-index: 50;
  }

  @media screen and (max-width: 480px) {
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const NavItem = styled.li`
  text-decoration: none;
  list-style: none;
`;

const NavLinks = styled(LinkR)`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: default;
  color: #000000;
  transition: 0.2s;
  margin-bottom: 18px;
  box-shadow: 0px 0.757576px 6.81818px rgba(0, 0, 0, 0.25);
  border-radius: 7.57576px;
  background: gray;
  pointer-events: none;

  ${(props) => {
    if (props.enable) {
      return css`
        background: #ffffff;
        pointer-events: visible;

        &:hover {
          background-color: #258d00;
          color: #fff;
          border-radius: 10px;
          transform: translateX(54px);
          width: 150px;
          padding: 0px 10px;
          cursor: pointer;

          p {
            display: flex;
          }
        }
      `;
    }
  }}

  &:active {
    background-color: #258d00;
    color: #fff;
  }

  p {
    display: none;
    width: 100%;
    margin-left: 10px;
    font-size: 13.8px;
    font-weight: bold;
  }
`;

const NavLinks2 = styled(LinkR)`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: #000000;
  transition: 0.2s;
  margin-bottom: 18px;
  background: #ffffff;
  box-shadow: 0px 0.757576px 6.81818px rgba(0, 0, 0, 0.25);
  border-radius: 7.57576px;

  &:active {
    background-color: #258d00;
    color: #fff;
  }

  &:hover {
    background-color: #258d00;
    color: #fff;
    border-radius: 10px;
    transform: translateX(54px);
    width: 150px;
    padding: 0px 10px;

    p {
      display: flex;
    }
  }

  p {
    display: none;
    width: 100%;
    margin-left: 10px;
    font-size: 13.8px;
    font-weight: bold;
  }
`;

const Menu = styled(CgMenu)`
  color: #258d00;
  justify-content: center;
  text-align: center;
  font-size: 45px;
  margin-bottom: -15px;
  margin-right: -10px;

  @media screen and (max-width: 480px) {
    font-size: 33px;
    margin-bottom: -11px;
  }
`;

const Home = styled(TbHome)`
  font-size: 30px;
`;
const Inventory = styled(MdOutlineInventory)`
  font-size: 30px;
`;

const Cubes = styled(FaCubes)`
  font-size: 30px;
`;
const Car = styled(MdOutlineLocalShipping)`
  font-size: 30px;
`;

const Hands = styled(FaRegHandshake)`
  font-size: 30px;
`;

const People = styled(IoIosPeople)`
  font-size: 30px;
`;
const Person = styled(MdOutlinePersonOutline)`
  font-size: 30px;
`;

const Logout = styled(MdLogout)`
  font-size: 30px;
`;

const NavMenu2 = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const UserInformation = styled.div`
  position: relative;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  z-index: 20;

  @media screen and (max-width: 480px) {
    margin-left: 15px;
  }
`;

const NameUser = styled.h6`
  font-size: 20px;
  color: #258d00;
  font-weight: bold;
  width: 100vw;
  text-align: start;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const RolUser = styled.p`
  font-size: 16px;
  font-weight: 500;
  width: 100vw;
  text-align: start;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const EditButton = styled.button`
  display: flex;
  height: 100%;
  margin-left: auto;
  margin-right: 18px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 21px;
  color: #fff;
  border-radius: 5px;
  background-color: #258d00;
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline-start: 60px;
`;
