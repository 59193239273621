import Cards from "./Cards";
import styled from "styled-components";
import NavBar from "../NavBar/NavBar";
import { useState } from "react";
import { SideBar } from "../SideBar/SideBar";

export default function Home(params) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MotherContainer>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Cards />
    </MotherContainer>
  );
}

const MotherContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  background-color: #232c26; 
  grid-gap: 10px; 
  height: 100vh;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding: 20px 20px; 
  }
`;
