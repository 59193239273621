import React from "react";
import { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import SearchBar from "../../SearchBar/SearchBar";
import "./FormDrop.css";

function FormDrop({
  title,
  search = true,
  placeholder,
  searchPlaceholder,
  searchHandler,
  searchType,
  handler,
  data,
  dataProps,
  children,
  placeholderHandler,
  enabled = true,
  dataConector,
}) {
  const [isDropped, setIsDropped] = useState(false);

  const selectData = (e) => {
    let id = e.target.querySelector('.form__identifier').innerText;
    const value = e.target.innerText;
    handler(id, value);
    placeholderHandler(value);
    closeDrop();
  };

  const closeDrop = () => {
    if (isDropped && searchHandler) {
      searchHandler();
    }
    setIsDropped((prev) => !prev);
  };

  return (
    <div
      className={
        enabled
          ? "input__container"
          : "input__container input__container--disabled"
      }
    >
      <p>
        {title} <span>*</span>
      </p>
      <button
        type="button"
        className="form__input form__drop"
        onClick={enabled ? closeDrop : undefined}
      >
        <span>{placeholder}</span>
        <RiArrowDropDownLine className="form__arrow" />
      </button>
      {isDropped && (
        <div className="form__dropdown ">
          {search && (
            <SearchBar
              placeholder={searchPlaceholder}
              location="form"
              type={searchType}
              handleEvent={searchHandler}
              reset={isDropped}
            />
          )}
          {data?.length > 0 ? (
            <div className="form__results">
              {data.map((item) => (
                <div
                  key={item.id}
                  className="form__result"
                  onClick={selectData}
                >
                  <p>
                    <span className="form__identifier">{item.id}</span>
                    {dataConector[0]}
                    {dataProps.map((prop, key) => {
                      return `${item[prop]} ${
                        key < dataProps.length - 1
                          ? `${dataConector[1] ? dataConector[1] : ""}`
                          : ""
                      }`;
                    })}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="form__empty">No hay resultados</p>
          )}
          {children}
        </div>
      )}
    </div>
  );
}

export default FormDrop;
