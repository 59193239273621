import { MdOutlineMarkEmailRead } from "react-icons/md";
export default function Check_your_email() {
  return (
    <div className="h-screen bg-green-100 flex justify-center items-center">
      <section className="">
        <div className="justify-center p-10 rounded-lg ">
          <div className="flex justify-center items-center">
            <MdOutlineMarkEmailRead className="text-green-700 text-9xl" />
          </div>
          <div className="flex flex-col justify-center items-center ">
            <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold mb-5 text-slate-700">
              Revisa tu correo electrónico
            </h1>
            <h3 className="text-1xl sm:text-3xl xl:text-4xl text-slate-700">
              Recibiras un enlace para cambiar la contraseña
            </h3>
          </div>
        </div>
      </section>
    </div>
  );
}
