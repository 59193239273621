import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai"
import { useState } from "react";
import axios from "axios";
import { useAuth } from ".";
import serverUri from "../../config/config";
const URL = serverUri;

export default function New_password(params) {
  const location = useLocation();
  const navigate = useNavigate();
  const {isExpired} = useAuth();
  const [error, setError] = useState(false);

  const handleClickToBack = () => {
    navigate(-1)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const {password, rePassword} = data;
    if(password !== rePassword) {
      setError(true);
    } else {
      setError(false);
      const token = new URLSearchParams(location.search).get('token');
      try {
        await axios.post(`${URL}/auth/change-password`, {token, password});
        navigate("/password_created");
      } catch (error) {
        isExpired(true);
        navigate('/recovery');
      }
    }
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center ">
      <div className="w-full pb-32 mt-[-200px] xl:hidden" >
        <button onClick={handleClickToBack} className="mt-10 ml-4 sm:ml-8 ">
          <AiOutlineArrowLeft className="text-green-700 text-3xl" />
        </button>
      </div>
      <section className="">
        <div className="justify-center p-10 rounded-lg justify-center p-10 rounded-lg ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center mb-10">
              <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold text-green-700 mb-5">
                Ingresa tu nueva contraseña
              </h1>
            </div>
            <div className="mb-6">
              <h3 className="font-semibold mb-2 sm:text-2xl xl:text-3xl">Nueva contraseña</h3>
              <input
                name="password"
                type="password"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="example@example.com"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.password?.message}</p>
            </div>
            <div className="mb-6">
              <h3 className="font-semibold mb-2 sm:text-2xl xl:text-3xl">Confirmar nueva contraseña</h3>
              <input
                name="password"
                type="password"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="example@example.com"
                {...register("rePassword", {
                  required: {
                    value: true,
                    message: "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.rePassword?.message}</p>
              {error && <p className="text-red-500">Las contraseñas no coinciden</p>}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex justify-center text-center mt-1 rounded-full px-6 xl:px-32 py-2 bg-green-700 text-white font-semibold sm:text-2xl hover:bg-green-400 hover:shadow-lg focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out"
              >
                Crear contraseña
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
