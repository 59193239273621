export function formaterNumbertoPrice(number) {
  if (number) {
    /* const options = { style: "currency", currency: "COL" };
    const numberFormat = new Intl.NumberFormat("en-latn-US", options); */
    return "$ " + new Intl.NumberFormat().format(number);
  }else return "$0"
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  return [day, month, year].join("/");
}
