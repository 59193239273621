import React, { useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  setReferenceFilter,
  showReferenceFilter,
} from "../../../features/stockSlice";
import Form from "../../Form/Form";

function ReferenceFilter() {
  const dispatch = useDispatch();
  const refFilter = useSelector(showReferenceFilter);
  return (
    <>
      <h2 className="modal__title">Filtros</h2>
      <Form>
        <div className="input__container">
          <div
            className="form__input form__input--check"
            onClick={() => {
              dispatch(
                setReferenceFilter({
                  ...refFilter,
                  outstock: !refFilter.outstock,
                  overstock: false,
                })
              );
            }}
          >
            <span>Outstock</span>
            <div className="form__check">
              {refFilter.outstock && <ImCheckboxChecked className="form__checked" />}
            </div>
          </div>
        </div>
        <div className="input__container">
          <div
            className="form__input form__input--check"
            onClick={() => {
                dispatch(
                    setReferenceFilter({
                      ...refFilter,
                      overstock: !refFilter.overstock,
                      outstock: false,
                    })
                  );
            }}
          >
            <span>Overstock</span>
            <div className="form__check">
              {refFilter.overstock && <ImCheckboxChecked className="form__checked" />}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ReferenceFilter;
