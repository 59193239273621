import Card1 from "./CardsCarpet/Card1";
import Card2 from "./CardsCarpet/Card2";
import styled from "styled-components";

export default function Cards(params) {
  return (
    <Contenedor>
      <ContainerCard1>
        <Card1 name="Notificaciones" className="Tarjeta1" />
      </ContainerCard1>
      <ContainerCard2>
        <Card2 name="Inventario" />
      </ContainerCard2>
      <ContainerCard3>
        <Card2 name="APU's" className="Tarjeta2" />
      </ContainerCard3>
      <ContainerCard4>
        <Card2 name="Traslados" />
      </ContainerCard4>
      <ContainerCard5>
        <Card2 name="Balance General" />
      </ContainerCard5>
    </Contenedor>
  );
}

const Contenedor = styled.div`
  width: 100%;
  display: grid;
  height: auto;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
`;

const ContainerCard1 = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-area: 2 / 1 / 3 / 2;
  //grid-auto-flow: row;

  /* @media screen and (max-width: 1024px) {
    grid-area: 2 / 1 / 3 / 2;

    //grid-auto-flow: column;
  } */

  @media screen and (max-width: 680px) {
    grid-auto-flow: row;
  }
`;

const ContainerCard2 = styled.div`
  display: flex;
  grid-area: 1 / 1 / 2 / 3;

  @media screen and (max-width: 1024px) {
    grid-auto-flow: row;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-top: 5px;
  }
`;

const ContainerCard3 = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  width: 100%;
  align-items: stretch;
`;

const ContainerCard4 = styled.div`
  grid-area: 3 / 1 / 4 / 2;

/*   @media screen and (max-width: 1024px) {
    grid-area: 3 / 1 / 4 / 3;
  } */
`;

const ContainerCard5 = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  height: 100%;

  /* @media screen and (max-width: 1024px) {
    grid-area: 2 / 2 / 3 / 3;
  } */
`;
