import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlineWarningAmber } from "react-icons/md";
import styled from "styled-components";
import {
  getDashboardPendingAsync,
  getDashboardNewsAsync,
  showPending,
  showNews,
} from "../../../features/dashboardSlice";
import Loading from "../../../utils/loading/Loading.js.js";
import ModalNotis from "../ModalNotifications/ModalNotis";
import ModalStock from "../ModalStock/ModalStock";
import { useAuth } from "../../auth";

export default function Card1({ name }) {
  const dispatch = useDispatch();
  const news = useSelector(showNews);
  const pending = useSelector(showPending);
  const {authState} = useAuth();
  
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getDashboardNewsAsync());
    dispatch(getDashboardPendingAsync());
  }, [dispatch]);

  const [showModalStock, setShowModalStock] = useState(false);
  const [data, setData] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    rol: "",
  });

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const openModalStock = () => {
    setShowModalStock((prev) => !prev);
  };

  const handleClick = (e, type = " stock ") => {
    const { value } = e.target;
    const valueParsed = JSON.parse(value);
    if (type === "user") {
      setData(valueParsed);
      openModal();
    } else {
      setData([type, valueParsed]);
      openModalStock();
    }
  };

  if (name === "Notificaciones") {
    try {
      return (
        <>
          <ContainerCard>
            <h2>{name}</h2>
            <Wrapper>
              {authState.user.rol === "admin" && news.newUsers?.map((e) => (
                <InfoContent key={e.email}>
                  <ContentNotis>
                    <IconContainer>
                      <IconUser />
                    </IconContainer>
                    <Resume>
                      <div className="hola">
                        <Title className="font-bold">Nuevo Usuario</Title>
                        <P>{e.email}</P>
                      </div>
                    </Resume>
                  </ContentNotis>
                  <InfoButton
                    value={JSON.stringify(e)}
                    onClick={(e) => handleClick(e, "user")}
                  >
                    + Info
                  </InfoButton>
                </InfoContent>
              ))}
              
              {news.overstock?.map((e) => (
                <InfoContent key={e.id}>
                  <ContentNotis>
                    <IconContainer>
                      <WarningIcon />
                    </IconContainer>
                    <Resume>
                      <div className="hola">
                        <Title className="font-bold ">Overstock</Title>
                        <P>{e.description}</P>
                      </div>
                    </Resume>
                  </ContentNotis>
                  <InfoButton
                    value={JSON.stringify(e)}
                    onClick={(e) => handleClick(e, "Overstock")}
                  >
                    + Info
                  </InfoButton>
                </InfoContent>
              ))}
              {news.outstock?.map((e) => (
                <InfoContent key={e.id}>
                  <ContentNotis>
                    <IconContainer>
                      <AlertIcon />
                    </IconContainer>
                    <Resume>
                      <div className="hola">
                        <Title className="font-bold ">Outstock</Title>
                        <P>{e.description}</P>
                      </div>
                    </Resume>
                  </ContentNotis>
                  <InfoButton
                    value={JSON.stringify(e)}
                    onClick={(e) => handleClick(e, "Outstock")}
                  >
                    + Info
                  </InfoButton>
                </InfoContent>
              ))}
            </Wrapper>
          </ContainerCard>
          <ModalNotis
            data={data}
            setData={setData}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <ModalStock
            data={data}
            showModalStock={showModalStock}
            setShowModalStock={setShowModalStock}
          />
        </>
      );
    } catch (error) {
      console.log("Ocurrio un error inesperado");
      //return alert("Ocurrio un error inesperado")
    }
  } //else if (name === "Pendientes") {
  //   try {
  //     return (
  //       <>
  //         <ContainerCard>
  //           <h2>{name}</h2>
  //           {pending.qualifications.map((e) => (
  //             <InfoContent>
  //               <div className="content">
  //                 <div>
  //                   <p>{e.name}</p>
  //                   <p>{e.stock}</p>
  //                   <p>{e.outstock}</p>
  //                 </div>
  //               </div>
  //               <InfoButton>+ Info</InfoButton>
  //             </InfoContent>
  //           ))}
  //           {news.outStock?.map((e) => (
  //             <InfoContent>
  //               <div className="content">
  //                 <AlertIcon />
  //                 <div>
  //                   <h3>{e.name}</h3>
  //                   <p>{e.email}</p>
  //                 </div>
  //               </div>
  //               <InfoButton>+ Info</InfoButton>
  //             </InfoContent>
  //           ))}
  //         </ContainerCard>
  //       </>
  //     );
  //   } catch (error) {
  //     console.log("Ocurrio un error inesperado");
  //     return (
  //       <>
  //         <ContainerCard>
  //           <h2>{name}</h2>
  //           <Loading />
  //         </ContainerCard>
  //       </>
  //     );
  //   }
  // } 
else if (name === "Balance general") {
    try {
      return (
        <>
          <ContainerCard>
            <h2>{name}</h2>
            {pending.qualifications.map((e) => (
              <InfoContent>
                <div className="content">
                  <div>
                    <p>{e.name}</p>
                    <p>{e.stock}</p>
                    <p>{e.outstock}</p>
                  </div>
                </div>
                <InfoButton>+ Info</InfoButton>
              </InfoContent>
            ))}
            {news.outStock?.map((e) => (
              <InfoContent>
                <div className="content">
                  <AlertIcon />
                  <div>
                    <h3>{e.name}</h3>
                    <p>{e.email}</p>
                  </div>
                </div>
                <InfoButton>+ Info</InfoButton>
              </InfoContent>
            ))}
          </ContainerCard>
        </>
      );
    } catch (error) {
      console.log("Ocurrio un error inesperado");
      return (
        <>
          <ContainerCard>
            <h2>{name}</h2>
            <Loading />
          </ContainerCard>
        </>
      );
    }
  }
}

const ContainerCard = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 50vh;

  .content {
    display: flex;
  }

  h2 {
    margin: 0px 15px;
    font-size: 22px;
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .hola {
      display: flex;
      flex-direction: column;
    }

    h2 {
      font-size: 22.3px;
    }
  }

  @media screen and (max-width: 640px) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    word-wrap: break-word;
    height: 228px;

    h2 {
      font-size: 19px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {display: none;}

`;

const InfoContent = styled.div`
  margin: 5px 2px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px 5px;
  background-color: #c7ded5;

  @media screen and (max-width: 1024px) {
    margin: 11.5px 2px;
  }

  @media screen and (max-width: 480px) {
    height: 52px;
    margin: 8px 2px;
  }
`;
const ContentNotis = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Resume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 16px;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 13.5px;
  }
`;

const P = styled.p`
  font-weight: 600;
  font-size: 12.5px;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 10.6px;
  }
`;

const IconUser = styled(MdOutlinePersonOutline)`
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin: 0px 6px;
  color: #258d00;
`;

const AlertIcon = styled(FiAlertCircle)`
  font-size: 30px;
  display: flex;
  margin: 0px 6px;
  color: #258d00;
`;

const WarningIcon = styled(MdOutlineWarningAmber)`
  font-size: 30px;
  display: flex;
  margin: 0px 6px;
  color: #258d00;
`;

const InfoButton = styled.button`
  font-size: 11px;
  white-space: nowrap;
  background-color: #fff;
  color: #258d00;
  margin: 15px 10px;
  padding: 0px 11px;
  border-radius: 5px;
  font-weight: bold;
  text-overflow: clip;

  @media screen and (max-width: 480px) {
    margin: 10px 5px;
  }
`;
