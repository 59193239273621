import React from "react";
import styled, {css} from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkR } from "react-router-dom";
import { TbHome } from "react-icons/tb";
import {
  MdOutlineInventory,
  MdOutlineLocalShipping,
  MdOutlinePersonOutline,
  MdLogout,
} from "react-icons/md";
import { FaRegHandshake, FaCubes } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import {useAuth} from "./../auth";

export const SideBar = ({ isOpen, toggle }) => {
  const {onLogout} = useAuth();
  return (
    <SiderbarContainer isOpen={isOpen}>
      <SidebarWrapper>
        <SidebarMenu>
          <Content>
            <ContentSide>
              <Icon>
                <CloseIcon onClick={toggle} />
              </Icon>
              <div style={{ marginTop: "20px" }}>
                <SidebarLink id="one" to="/dashboard" onClick={toggle} enable>
                  <Home/>
                  <p>Dashboard</p>
                </SidebarLink>
                <SidebarLink to="/stock/references" onClick={toggle} enable>
                  <Inventory/>
                  <p>Inventario</p>
                </SidebarLink>
                {/* <SidebarLink to="Bye" onClick={toggle}>
                  <Cubes/>
                  <p>APU's</p>
                </SidebarLink>
                <SidebarLink id="two" to="Form" onClick={toggle}>
                  <Car/>
                  <p>Traslados</p>
                </SidebarLink> */}
                <SidebarLink to="/suppliers" onClick={toggle} enable>
                  <Hands/>
                  <p>Proveedores</p>
                </SidebarLink>
                {/* <SidebarLink id="two" to="Form" onClick={toggle}>
                  <People/>
                  <p>Clientes</p>
                </SidebarLink>
                <SidebarLink id="two" to="Form" onClick={toggle}>
                  <Person/>
                  <p>Usuarios</p>
                </SidebarLink> */}
              </div>
            </ContentSide>
            <div>
              <SidebarLink id="/login" enable onClick={() => onLogout(false)} >
                <Logout/>
                <p>Salir</p>
              </SidebarLink>
            </div>
          </Content>
        </SidebarMenu>
      </SidebarWrapper>
    </SiderbarContainer>
  );
};

const SiderbarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  margin-top: -10px;
  justify-content: end;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  transition: 0.5s;
`;

export const SidebarMenu = styled.ul`
  width: 276px;
  margin-top: 10px;
  margin-right: 20px;
  right: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  transition: 1s ease-in-out;
  height: 678px;
  justify-content: space-between;
  background: #c7ded5;
  text-align: center;
  border-radius: 20px;

  @media screen and (max-width: 480px) {
    width: 217px; 
  }
`;

const CloseIcon = styled(FaTimes)`
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const Icon = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 3rem;
  cursor: pointer;
  outline: none;
`;

const SidebarWrapper = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarLink = styled(LinkR)`
position: relative;
  margin: 0px 24px 18px 24px;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #000;
  width: 228px;
  height: 49px;
  font-weight: bold;
  box-shadow: 0px 0.751737px 6.76563px rgba(0, 0, 0, 0.25);
  border-radius: 7.51737px;
  pointer-events: none;
  z-index: 30;

  p {
    font-size: 18px;
    margin-left: 8px;
  }

  @media screen and (max-width:480px) {
    width: 169px;
  }

  ${(props) => {
    if (props.enable) {
      return css`
      background: #ffffff;
      pointer-events: visible;

        &:hover {
          background-color: #258d00;
          color: #fff;
          border-radius: 10px;
          transform: translateX(54px);
          width: 150px;
          padding: 0px 10px;
          cursor: pointer;

          p {
            display: flex;
          }
        }
      `;
    }
  }}

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;
`;
const ContentSide = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Home = styled(TbHome)`
  font-size: 30px;
`;
const Inventory = styled(MdOutlineInventory)`
  font-size: 30px;
`;

const Cubes = styled(FaCubes)`
  font-size: 30px;
`;
const Car = styled(MdOutlineLocalShipping)`
  font-size: 30px;
`;

const Hands = styled(FaRegHandshake)`
  font-size: 30px;
`;

const People = styled(IoIosPeople)`
  font-size: 30px;
`;
const Person = styled(MdOutlinePersonOutline)`
  font-size: 30px;
`;

const Logout = styled(MdLogout)`
  font-size: 30px;
`;


