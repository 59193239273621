import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: {
    info: {
      name: "",
      title: "",
      accept: "",
      reject: "",
      errorMssg: "",
      button1: false,
      button2: false,
      button3: false,
      select1: false,
      select2: false,
      select3: false,
      input1: false,
      parrafo: false,
    },
    newEntries: false,
    details: false,
    decision: false,
    accept: false,
    addElement: false,
    reference: false,
    input: false,
  },
  transferQuantity: 0,
  
};

export const helpSlide = createSlice({
  name: "help",
  initialState,
  reducers: {
    changeStatusModalNewEntries: (state, action) => {
      state.modals.newEntries = !state.modals.newEntries;
    },
    changeStatusModalDetails: (state, action) => {
      state.modals.details = !state.modals.details;
    },
    changeStatusModalDecision: (state, action) => {
      state.modals.decision = !state.modals.decision;
    },
    changeStatusModalInfo: (state, action) => {
      const { title, accept, reject, type, name, errorMsg } = action.payload;
      state.modals.info.title = title;
      state.modals.info.accept = accept;
      state.modals.info.reject = reject;
      state.modals.info.name = name;
      state.modals.info.errorMsg = errorMsg;
      type.length && type.map((e) => (state.modals.info[e] = true));
      //console.log(`${typeof(state.modals.info.parrafo)} Antes`)
      //console.log(state.modals.info);
      //console.log(`${typeof(state.modals.info.parrafo)} Después`)
    },
    changeStatusModalAccept: (state, action) => {
      state.modals.accept = !state.modals.accept;
    },
    changeStatusModalAddElement: (state, action) => {
      state.modals.addElement = !state.modals.addElement;
    },
    changeStatusModalReference: (state, action) => {
      state.modals.reference = !state.modals.reference;
    },
    changeStatusModalInput: (state, action) => {
      state.modals.input = !state.modals.input;
    },
    setTransferQuantity: (state, action) => {
      state.transferQuantity = action.payload
    }
  },
});

export const {
  changeStatusModalNewEntries,
  changeStatusModalDetails,
  changeStatusModalDecision,
  changeStatusModalInfo,
  changeStatusModalAccept,
  changeStatusModalReference,
  changeStatusModalInput,
  setTransferQuantity
} = helpSlide.actions;
export const statusModalNewEntries = (state) => state.help.modals.newEntries;
export const statusModalDetails = (state) => state.help.modals.details;
export const statusModalDecision = (state) => state.help.modals.decision;
export const statusModalInfo = (state) => state.help.modals.info;
export const statusModalAccept = (state) => state.help.modals.accept;
export const statusModalReference = (state) => state.help.modals.reference;
export const statusModalInput = (state) => state.help.modals.input;
export const statusTransferQuantity = (state) => state.help.transferQuantity;

export default helpSlide.reducer;
