import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCostAsync,
  getSuppliersAsync,
  setNewCost,
  showCost,
  showNewCost,
  showReference,
  showSuppliers,
} from "../../../features/stockSlice";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";

function UpdateCosts() {
  const dispatch = useDispatch();
  const [supplier, setSupplier] = useState("Elige un proveedor");
  const [supplierSearch, setSupplierSearch] = useState("");
  const suppliers = useSelector(showSuppliers);
  const newCost = useSelector(showNewCost);
  const cost = useSelector(showCost);
  const reference = useSelector(showReference);

  useEffect(() => {
    dispatch(
      getCostAsync(
        reference.id,
        newCost.supplierId ? newCost.supplierId : "xxx",
      )
    );
  }, [supplier, dispatch]);

  useEffect(() => {
    dispatch(getSuppliersAsync(supplierSearch));
  }, [supplierSearch, dispatch]);

  useEffect(() => {
    const selectedCost = cost[cost.length - 1];
    const spCode = selectedCost?.supplierCode;
    dispatch(
      setNewCost({
        ...newCost,
        supplierCode: spCode ? spCode : "",
      })
    );
  }, [cost, dispatch]);

  const setSupplierId = (id) => {
    dispatch(setNewCost({ ...newCost, supplierId: id }));
    dispatch(getCostAsync(reference.id, id));
  };

  const setValuesNewCost = (e, prop) => {
    const { value } = e.target;

    dispatch(
      setNewCost({
        ...newCost,
        [prop]: value,
      })
    );
  };

  return (
    <>
      <Form>
        <FormDrop
          title="Proveedor"
          placeholder={supplier}
          searchPlaceholder="Nombre de proveedor"
          searchHandler={setSupplierSearch}
          searchType="submit"
          handler={setSupplierId}
          placeholderHandler={setSupplier}
          data={suppliers.filter((item) => {
            return item.id !== 1;
          })}
          dataProps={["fullName"]}
          dataConector={[""]}
        ></FormDrop>
        <div className="input__container">
          <p>Código de proveedor</p>
          <input
            type="text"
            value={newCost.supplierCode}
            className="form__input"
            onChange={(e) => setValuesNewCost(e, "supplierCode")}
          />
        </div>
        <div className="input__container">
          <p>
            Valor unitario <span>*</span>
          </p>
          <input
            type="number"
            className="form__input"
            value={newCost.cost}
            onChange={(e) => setValuesNewCost(e, "cost")}
          />
        </div>
      </Form>
    </>
  );
}

export default UpdateCosts;
