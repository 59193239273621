import React from 'react'
import {BiErrorCircle} from 'react-icons/bi';
import './Error.css';

function Error({mssg}) {
  return (
    <>
        <BiErrorCircle className='error__icon'/>
        <h2 className='modal__title modal__title--error'>Algo anda mal</h2>
        <p className='modal__text'>{mssg}</p>
    </>
  )
}

export default Error
