import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showInvoice } from "../../../features/stockSlice";
import { formaterNumbertoPrice } from "../../../utils/functions/reusableFunctions";
import Table from "../../Table/Table";

function InvoiceInfo({ setModal, closeModal }) {
  const invoice = useSelector(showInvoice);
  const columns = [
    "Código",
    "Cotización",
    "Cantidad de Elementos",
    "Costo Total",
    "Fecha",
  ];

  return (
    <>
      <h2 className="modal__title modal__title--alt">Información</h2>
      <div className="modal__info">
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Código: </span> {invoice.id}
          </p>
          <p>
            <span>Proveedor: </span> {invoice.supplier.fullName}
          </p>
          <p>
            <span>Fecha: </span> {invoice.date}
          </p>
        </div>
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Cantidad de remisiones: </span> {invoice.inputsQuantity}
          </p>
          <p>
            <span>Costo total: </span> {formaterNumbertoPrice(invoice.total)}
          </p>
          <p>
            <span>Responsable: </span>{" "}
            {`${invoice.user.firstName} ${invoice.user.lastName}`}
          </p>
        </div>
      </div>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Remisiones</h2>
        <Table alt="table" columns={columns}>
          <tbody className="table__body">
            {invoice.inputs.map((input) => (
              <tr key={input.id}>
                <td>{input.id}</td>
                <td>{input.quotation}</td>
                <td>{input.incomesQuantity * 1}</td>
                <td>{formaterNumbertoPrice(input.totalCost)}</td>
                <td>{input.date}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {invoice.inputs.length === 0 && (
          <p className="modal__text modal__empty">No hay registros</p>
        )}
      </div>
    </>
  );
}

export default InvoiceInfo;
