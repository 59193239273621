const supplierDefault = {
  id: null,
  docTypeId: null,
  fullName: "",
  contact: null,
  email: "",
};

const defaultModalState = {
  isActive: false,
  content: null,
  actionPrimary: "",
  actionSecondary: "",
  handlePrimary: null,
  partialSelector: null,
  asyncSelector: null,
  handleSecondary: null,
  onClose: null,
  errors: {
    required: "Los campos marcados con * son obligatorios",
  },
};

export { supplierDefault, defaultModalState };
