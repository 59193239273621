//defaultValues
export const referencesFilterInit = {
  searchValue: null,
  qualificationId: null,
  overstock: false,
  outstock: false,
};

export const defaultNewInput = {
  id: "",
  quotation: "",
  userId: null,
  supplierId: null,
  supplier: null,
  incomes: [],
};

export const defaultNewIncome = {
  reentry: false,
  supplierCode: "",
  inputId: null,
  referenceId: null,
  quantity: "",
  value: "",
  supplierId: null,
  referenceText: null,
  referenceMainId: null,
};

export const generalFilterInit = {
  searchValue: null,
  date: null,
};

export const defaultModalState = {
  isActive: false,
  content: null,
  actionPrimary: "",
  actionSecondary: "",
  handlePrimary: null,
  partialSelector: null,
  asyncSelector: null,
  handleSecondary: null,
  onClose: null,
  errors: {
    required: "Los campos marcados con * son obligatorios",
  },
};

export const defaultNewCost = {
  supplierId: null,
  supplierCode: "",
  referenceId: null,
  cost: "",
};

export const defaultEditReference = {
  qualificationId: null,
  origin: null,
  minLimit: null,
  maxLimit: null,
  unityWeight: null
}

export const defaultNewInvoice = {
  id: "",
  userId: null,
  supplierId: null,
  supplier: null,
  inputs: [],
}

export const defaultNewSup = {
  id: null,
  docTypeId: null,
  fullName: "",
  contact: null,
  email: ""
}

export const defaultNewRef = {
  description: "",
  magnitude: null,
  unityId: null,
  qualificationId: null,
  origin: null,
  minLimit: null,
  maxLimit: null,
  unityWeight: null
}
