import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import serverUri from "../../config/config";
const url =  serverUri;
const AuthContext = React.createContext();
const LOCAL_NAME = 'LOCAL_DATA'

const initialState = {
  error: false,
  user: null,
  token: null,
  signError: false,
  invalid: false,
  recoveryExpired: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.login:
      return {
        invalid: false,
        error: false,
        user: action.payload.user,
        token: action.payload.token,
      };
    case actionTypes.logout:
      return {
        ...state,
        user: null,
        token: null,
      };
    case actionTypes.error:
      return {
        ...state,
        invalid: false,
        error: true,
      }
    case actionTypes.signStatus:
      return {
        ...state,
        error:false,
        invalid: false,
        signError: action.payload.status
      }
    case actionTypes.invalid:
      return {
        ...state,
        invalid: true
      }
    case actionTypes.recoveryExpired:
      return {
        ...state,
        recoveryExpired: action.payload.expired
      }
    default:
      return state;
  }
};

const actionTypes = {
  login: "LOGIN",
  logout: "LOGOUT",
  error: "ERROR",
  signStatus: "SIGN_STATUS",
  invalid: "INVALID",
  recoveryExpired: "RECOVERY_EXPIRED"
};

let logOut;

function AuthProvider({ children }) {
  const navigate = useNavigate();
  
  const [authState, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {

    try {
      const localToken = localStorage.getItem(LOCAL_NAME);
      if (localToken) {
        const parsed = JSON.parse(localToken);
        dispatch({ type: actionTypes.login, payload: { user: parsed.user, token: parsed.token } });
      }
    } catch (error) {

    }

  }, [])

  const onSignUp = async (data) => {
    try {
      console.log(data);
      const rta = await axios.post(`${url}/auth/sign-up`, data);

      dispatch({type: actionTypes.signStatus, payload: {status: false}})
      navigate('/already_register');
    } catch (error) { 
      dispatch({type: actionTypes.signStatus, payload: {status: true}})
    }
  }

  const onLogin = async (email, password) => {
    try {
      const data = await axios.post(url+"/auth", {
        email,
        password,
      });
      if (data.data.user && data.data.user.rol !== 'new') {
        const { user, token } = data.data;
        localStorage.setItem(LOCAL_NAME, JSON.stringify({ user, token }));
        dispatch({ type: actionTypes.login, payload: { user, token } });
        navigate("/dashboard", { replace: true });
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };

  const onLogout = (isInvalid) => {
    if(isInvalid) dispatch({ type: actionTypes.invalid });
    localStorage.setItem(LOCAL_NAME, null);
    dispatch({ type: actionTypes.logout });
    navigate("/login", { replace: true });
  };

  const onError = () => {
    dispatch({ type: actionTypes.error });
  };

  const isExpired = (expired) => {
    dispatch({type: actionTypes.recoveryExpired, payload: {expired}});
    if(expired) navigate('/recovery');
  }

  const auth = { authState, onLogin, onLogout, onSignUp, isExpired };
  logOut = onLogout;
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const auth = React.useContext(AuthContext);
  return auth;
}


function AuthGuardian({ children, logged }) {
  const { authState } = useAuth();
  if (logged && !authState.user) {
    return <Navigate to="/login" />;
  } else if (!logged && authState.user) {
    return <Navigate to="/dashboard" />;
  } else {
    return children;
  }
}

export { AuthProvider, AuthGuardian, useAuth, LOCAL_NAME, logOut};
