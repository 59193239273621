import React from "react";
import styled, { css } from "styled-components";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import {
  MdOutlineInventory,
  MdOutlineArchive,
  MdOutlineMoveToInbox,
  MdOutlineOutbox,
} from "react-icons/md";
import { BsFilterLeft } from "react-icons/bs";
import Loading from "../../../utils/loading/Loading.js.js";
import { useEffect } from "react";
import {
  getDashboardStockAsync,
  showStock,
  getDashboardReportAsync,
  showReport,
} from "../../../features/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalFecha from "../ModalDate/ModalFecha.js";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  changeStatusTypesView,
  getStockHistoryAsync,
} from "../../../features/stockSlice.js";

const formatterPeso = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

export default function Card2({ name }) {
  const stock = useSelector(showStock);
  const report = useSelector(showReport);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardStockAsync());
    dispatch(getDashboardReportAsync());
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);
  const [stockDate, setStockDate] = useState(new Date());
  const [isFiltered, setIsFiltered] = useState(false);
  const [reportDate, setReportDate] = useState(new Date());
  const [isReportFiltered, setReportFiltered] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  const handleClickViewMore = () => {
    dispatch(getStockHistoryAsync());
    dispatch(changeStatusTypesView("history"));
  };
  if (name === "Inventario") {
    try {
      return (
        <>
          <CardContainer>
            <H3 className="Ppal">{name}</H3>
            <Content>
              <Container1 contentOne>
                <InfoContent>
                  <Divisor>
                    <FaRegMoneyBillAlt2 />
                    <H4>Valor total</H4>
                  </Divisor>
                  <H2>
                    {formatterPeso.format(
                      stock.totalStock ? stock.totalStock : 0
                    )}
                  </H2>
                </InfoContent>
                <InfoContent>
                  <Divisor>
                    <InventoryIcon />
                    <H4>Entradas</H4>
                    <FilterDate onClick={openModal}>
                      <Fecha>{isFiltered ? stockDate : "Filtro"}</Fecha>
                      <BsFilterLeft className="FilterIcon" />
                    </FilterDate>
                  </Divisor>
                  <Divisor2>
                    <H1>{stock?.inputsTotal}</H1>
                    <H5>Entradas de Inventario</H5>
                  </Divisor2>
                </InfoContent>
              </Container1>
              <Container2>
                <InfoContent second>
                  <Divisor>
                    <ArchiveIcon />
                    <H4 fuck>Entradas Recientes</H4>
                  </Divisor>
                  <InputContainer>
                    {stock?.inputs?.map((e) => (
                      <FieldsContent key={e.id}>
                        <div className="Name-Cod__Container">
                          <H6>N.º {e.id}</H6>
                          <H6>Cotización: {e.quotation}</H6>
                        </div>
                        <DateAndMoney className="Name-Cod__Container">
                          <P>{e.date ? e.date : new Date()}</P>
                          <h4>{formatterPeso.format(e.total ? e.total : 0)}</h4>
                        </DateAndMoney>
                      </FieldsContent>
                    ))}
                  </InputContainer>
                  <Link to="/stock/history">
                    <VerMas onClick={handleClickViewMore}>Ver más</VerMas>
                  </Link>
                </InfoContent>
              </Container2>
            </Content>
          </CardContainer>
          <ModalFecha
            setDate={setStockDate}
            setIsFiltered={setIsFiltered}
            showModal={showModal}
            setShowModal={setShowModal}
            parent="stock"
          />
        </>
      );
    } catch (error) {
      console.log("Ocurrio un error inesperado 1");
      return (
        <CardContainer>
          <H3>{name}</H3>
          <Loading />
          <br />
          <br />
          <br />
        </CardContainer>
      );
    }
    // } else if (name === "APU's") {
    //   try {
    //     return (
    //       <>
    //         <CardContainerAux>
    //           <H3>{name}</H3>
    //         </CardContainerAux>
    //       </>
    //     );
    //     // eslint-disable-next-line no-unreachable
    //   } catch (error) {
    //     console.log("Ocurrio un error inesperado 2");
    //     return (
    //       <>
    //         <CardContainer />
    //       </>
    //     );
    //   }
    // } else if (name === "Traslados") {
    //   try {
    //     return (
    //       <>
    //         <CardContainerAux2>
    //           <H3>{name}</H3>
    //         </CardContainerAux2>
    //       </>
    //     );
    //   } catch (error) {
    //     console.log("Ocurrio un error inesperado 3");
    //     return (
    //       <>
    //         <CardContainerAux>
    //           <H3>{name}</H3>
    //         </CardContainerAux>
    //       </>
    //     );
    //   }
    //
  } else if (name === "Balance General") {
    try {
      return (
        <>
          <CardContainer>
            <div style={{ display: "flex", marginBottom: "-10px" }}>
              <H3 Balance>{name}</H3>
              <FilterDate onClick={openModal} Filter>
                <Fecha>{isReportFiltered ? reportDate : "Filtro"}</Fecha>
                <BsFilterLeft className="FilterIcon" />
              </FilterDate>
            </div>
            <Container1>
              <Master>
                <InfoContent>
                  <Divisor>
                    <InboxIcon />
                    <H4>Entradas</H4>
                  </Divisor>
                  <H2>
                    {formatterPeso.format(
                      report.totalInput ? report.totalInput : 0
                    )}
                  </H2>
                </InfoContent>
                <InfoContent desactivado>
                  <Divisor>
                    <OutBoxIcon />
                    <H4>Traslados</H4>
                  </Divisor>
                  <H2>
                    {formatterPeso.format(
                      report.totalDeparture ? report.totalDeparture : 0
                    )}
                  </H2>
                </InfoContent>
              </Master>
              {/* <VerMas>Ver más</VerMas> */}
              {/* Se deshabilita el ver mas por el momento
              Para reemplazarlo pongo este div para no danar los estilos */}
              {/* <div className="h-10"></div> */}
            </Container1>
          </CardContainer>
          <ModalFecha
            setDate={setReportDate}
            setIsFiltered={setReportFiltered}
            showModal={showModal}
            setShowModal={setShowModal}
            parent="report"
          />
        </>
      );
    } catch (error) {
      console.log("Ocurrio un error inesperado 4");
      return (
        <>
          <CardContainer>
            <H3>{name}</H3>
            <Loading />
          </CardContainer>
        </>
      );
    }
  }
}

const InputContainer = styled.div`
  padding: 0px 10px;
  width: 100%;
  height: 190px;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;

  .Ppal {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 5px;
    align-items: stretch;
    word-wrap: break-word;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: -15px;
  display: grid;
  //justify-content: space-between;
  grid-template-columns: 1fr, 1fr, 1fr;
  grid-template-rows: (2, 1fr);

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: (4, 1fr);
    grid-template-rows: (2, 1fr);
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const Container1 = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.contentOne &&
    css`
      padding-right: 0px;
    `}
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    ${(props) =>
      props.contentOne &&
      css`
        padding: 10px;
      `}
  }
`;

const InfoContent = styled.div`
  width: 100%;
  background-color: #c7ded5;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 7px;
  align-self: auto;

  ${(props) =>
    props.second &&
    css`
      padding: 10px 10px;
      width: 100%;
      height: 100%;
    `}

  ${(props) =>
    props.desactivado &&
    css`
      background-color: #6c6c6c;
    `}
`;

const Container2 = styled.div`
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  grid-area: 1 / 2 / 2 / 4;

  @media screen and (max-width: 1024px) {
    padding-top: 10px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    word-wrap: break-word;
    margin-top: -20px;
  }
`;

const Divisor = styled.div`
  display: flex;
  padding: 0px 10px;
`;

const H1 = styled.h1`
  font-weight: bold;
  margin-right: 4px;
  font-size: 62px;
  color: #258d00;
  word-wrap: break-word;
  @media screen and (max-width: 1024px) {
    font-size: 62px;
  }

  @media screen and (max-width: 480px) {
    font-size: 60px;
  }
`;

const H2 = styled.h2`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: flex-start;
  margin-left: 10px;
  font-weight: bold;
  font-size: 43px;
  color: #258d00;

  @media screen and (max-width: 1024px) {
    font-size: 35px;
  }

  @media screen and (max-width: 480px) {
    inline-size: 250px;
    overflow-wrap: anywhere;
    font-size: 38px;
  }
`;

const H3 = styled.h3`
  font-weight: bold;
  align-items: center;
  padding-left: 10px;
  margin-top: 10px;
  padding-top: 10px;
  margin: 0px 15px;
  font-size: 26px;

  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;

    ${(props) =>
      props.Balance &&
      css`
        margin-top: 6px;
      `}
  }
`;

const H4 = styled.h4`
  font-weight: bold;
  font-size: 26px;

  @media screen and (max-width: 1024px) {
    font-size: 28px;

    ${(props) =>
      props.fuck &&
      css`
        font-size: 21px;
        width: 100%;
        margin-top: 3px;
      `}
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const H5 = styled.h5`
  display: flex;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  font-size: 19px;
  margin-top: 10px;

  @media screen and (max-width: 1024px) {
    padding: 15px 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
    padding: 0;
    justify-self: start;
    text-align: start;
    line-height: 23px;
    margin-inline-start: 20px;
  }
`;

const H6 = styled.h6`
  font-size: 15px;
  color: #fff;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const P = styled.p`
  color: #fff;
  font-size: 13px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`;

const FilterDate = styled.button`
  font-weight: bold;
  background-color: #fff;
  color: #258d00;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  gap: 5px;
  margin-left: auto;
  margin-right: -10px;
  z-index: 0;

  .FilterIcon {
    font-size: 22px;
  }

  @media screen and (max-width: 1024px) {
    .FilterIcon {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;

    .FilterIcon {
      font-size: 15px;
      margin-left: 3px;
    }
  }

  ${(props) =>
    props.Filter &&
    css`
      font-size: 20px;
      background-color: #232c26;
      color: #fff;
      margin-top: 10px;
      height: 40px;
      text-align: start;
      margin-right: 10px;
      margin-left: 0;
      z-index: 0;

      .FilterIcon {
        font-size: 22px;
      }
    `}
`;

const Fecha = styled.p`
  font-size: 13px;

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

const Divisor2 = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
`;

const FieldsContent = styled.div`
  width: 100%;
  background-color: #232c26;
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
  border-radius: 5px;
  padding: 5px 13px;
  margin: 8px 0px;

  div {
    width: 100%;
  }

  .Name-Cod__Container {
    margin-top: 5px;
  }

  h4 {
    color: #fff;
  }
`;

const DateAndMoney = styled.div`
  width: 100%;

  h4 {
    font-size: 23px;
    font-weight: bold;
    display: flex;
    justify-content: end;
    text-align: center;
    position: relative;

    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }

    @media screen and (max-width: 480px) {
      inline-size: 125px;
      overflow-wrap: anywhere;
      font-size: 20px;
    }
  }

  p {
    text-align: right;
  }
`;

const FaRegMoneyBillAlt2 = styled(FaRegMoneyBillAlt)`
  margin-right: 5px;
  font-size: 38px;
  color: #258d00;

  @media screen and (max-width: 1024px) {
    font-size: 35px;
    margin-top: 3px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
    margin-top: 3px;
  }
`;

const InventoryIcon = styled(MdOutlineInventory)`
  font-size: 35px;
  margin-right: 5px;
  margin-top: 3px;
  color: #258d00;

  @media screen and (max-width: 480px) {
    font-size: 28px;
    margin-top: 4px;
  }
`;

const ArchiveIcon = styled(MdOutlineArchive)`
  //padding: 10px 0px 10px 10px;
  font-size: 35px;
  margin-right: 5px;
  color: #258d00;

  @media screen and (max-width: 1024px) {
    font-size: 35px;
    margin-top: 3px;
  }

  @media screen and (max-width: 480px) {
    font-size: 30px;
    margin-top: 3px;
  }
`;

const VerMas = styled.button`
  background-color: #fff;
  width: 100%;
  margin: 5px 0px;
  border-radius: 10px;
  padding: 2.5px 0px;
  font-weight: bold;
  background-color: #258d00;
  color: #fff;

  bottom: 0px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const InboxIcon = styled(MdOutlineMoveToInbox)`
  margin-right: 5px;
  font-size: 26px;
  margin-top: 6px;
  color: #258d00;

  @media screen and (max-width: 1024px) {
    font-size: 33px;
    margin-top: 5px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
    margin-top: 3px;
  }
`;

const OutBoxIcon = styled(MdOutlineOutbox)`
  margin-right: 5px;
  font-size: 26px;
  margin-top: 6px;
  color: #010101;

  @media screen and (max-width: 1024px) {
    font-size: 33px;
    margin-top: 5px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
    margin-top: 3px;
  }
`;

const Master = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 10px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    margin-bottom: 5px;
  }
`;
