import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getInvoicesAsync,
  getSuppliersAsync,
  setNewInvoice,
  showNewInvoice,
  showSuppliers,
} from "../../../features/stockSlice";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";

function NewInvoicePartial() {
  const dispatch = useDispatch();
  const newInvoice = useSelector(showNewInvoice);
  const [supplier, setSupplier] = useState("Elige un proveedor");
  const [supplierSearch, setSupplierSearch] = useState("");
  const suppliers = useSelector(showSuppliers);

  useEffect(() => {
    dispatch(getSuppliersAsync(supplierSearch));
  }, [supplierSearch, dispatch]);

  const setSupplierId = (id, supplier) => {
    dispatch(setNewInvoice({ ...newInvoice, supplierId: id, supplier }));
  };

  return (
    <>
      <h2 className="modal__title">Nueva Factura</h2>
      <Form>
        <div className="input__container">
          <p>
            Código de factura <span>*</span>
          </p>
          <input
            className="form__input"
            type="text"
            onChange={(e) => {
              dispatch(setNewInvoice({ ...newInvoice, id: e.target.value }));
              dispatch(getInvoicesAsync("", null, e.target.value, true));
            }}
            required
          />
          <FormDrop
            title="Proveedor"
            placeholder={supplier}
            searchPlaceholder="Nombre de proveedor"
            searchHandler={setSupplierSearch}
            handler={setSupplierId}
            placeholderHandler={setSupplier}
            data={suppliers.filter(item => item.id !== 1)}
            dataProps={["fullName"]}
            dataConector={[""]}
          ></FormDrop>
        </div>
      </Form>
    </>
  );
}

export default NewInvoicePartial;
