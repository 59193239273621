import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showSupplier } from "../../features/supplierSlice";
import Loading from "../../utils/loading/Loading.js";

function SupplierInfo() {
  const [isLoading, setIsLoading] = useState(true);
  const supplier = useSelector(showSupplier);

  useEffect(() => {
    setIsLoading(false);
  }, [supplier]);

  return Object.keys(supplier).length === 0 || isLoading ? (
    <>
      <Loading />
    </>
  ) : (
    <>
      <h2 className="modal__title modal__title--alt">Información</h2>
      <div>
        <div className="modal__accum modal__text">
          <p>
            <span>{supplier.docType.description}:</span> {supplier.id}
          </p>
          <p>
            <span>Nombre:</span> {supplier.fullName}
          </p>
          <p>
            <span>Contacto:</span> {supplier.contact || "No Aplica"}
          </p>
          <p>
            <span>Correo Electrónico:</span> {supplier.email || "No Aplica"}
          </p>
        </div>
      </div>
    </>
  );
}

export default SupplierInfo;
