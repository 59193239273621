import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockHistoryAsync,
  getSuppliersAsync,
  postSuppliersAsync,
  setNewInput,
  showNewInput,
  showNewSup,
  showSuppliers,
} from "../../../features/stockSlice";
import Error from "../../Error/Error";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";
import NewSupplier from "../NewSupplier";

function NewInputPartial({ setModal, closeModal }) {
  const [supplier, setSupplier] = useState("Elige un proveedor");
  const [supplierSearch, setSupplierSearch] = useState("");
  const dispatch = useDispatch();
  const newInput = useSelector(showNewInput);
  const suppliers = useSelector(showSuppliers);

  useEffect(() => {
    dispatch(getSuppliersAsync(supplierSearch));
  }, [supplierSearch, dispatch]);

  const setSupplierId = (id, supplier) => {
    dispatch(setNewInput({ ...newInput, supplierId: id, supplier }));
  };

  const validateSupplier = (newSup, supps) => {
    dispatch(getSuppliersAsync());
    setTimeout(() => {
      const { id, docTypeId, fullName, contact } = newSup;
      if (!id || !docTypeId || !fullName || !contact) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.required} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else if (supps.some((item) => item.id === newSup.id)) {
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: <Error mssg={prev.crudForm.errors.alreadyExists} />,
            actionPrimary: "Aceptar",
            handlePrimary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
      } else {
        const { email, ...finalSup } = newSup;
        if (email) finalSup.email = email;

        dispatch(postSuppliersAsync(finalSup));
        setTimeout(() => {
          dispatch(getSuppliersAsync());
        }, 300);
        closeModal("crudForm", "newSup");
      }
    }, 100);
  };

  const addSupplier = () => {
    setModal((prev) => ({
      ...prev,
      crudForm: {
        isActive: true,
        actionPrimary: "Aceptar",
        handlePrimary: validateSupplier,
        partialSelector: showNewSup,
        asyncSelector: showSuppliers,
        content: <NewSupplier />,
        onClose: () => closeModal("crudForm", "newSup"),
        errors: {
          ...prev.partial.errors,
          alreadyExists: "Ya existe un proveedor con esta identificación",
        },
      },
    }));
  };

  return (
    <>
      <h2 className="modal__title">Nueva entrada de inventario</h2>
      <Form>
        <div className="input__container">
          <p>
            Código de cotización <span>*</span>
          </p>
          <input
            className="form__input"
            type="text"
            onChange={(e) =>
              dispatch(setNewInput({ ...newInput, quotation: e.target.value }))
            }
          />
        </div>
        <div className="input__container">
          <p>
            Código de remisión <span>*</span>
          </p>
          <input
            className="form__input"
            type="text"
            onChange={(e) => {
              dispatch(setNewInput({ ...newInput, id: e.target.value }));
              dispatch(getStockHistoryAsync("", e.target.value));
            }}
          />
        </div>
        <FormDrop
          title="Proveedor"
          placeholder={supplier}
          searchPlaceholder="Nombre de proveedor"
          searchHandler={setSupplierSearch}
          handler={setSupplierId}
          placeholderHandler={setSupplier}
          data={suppliers}
          dataProps={["fullName"]}
          dataConector={[""]}
        >
          <button onClick={() => addSupplier()} className="form__newItem">
            Nuevo Proveedor
          </button>
        </FormDrop>
      </Form>
    </>
  );
}

export default NewInputPartial;
