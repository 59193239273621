import React from "react";
import ReactDOM from "react-dom";
import { VscChromeClose } from "react-icons/vsc";
import { useSelector } from "react-redux";
import "./Modal.css";

//info states

function Modal({
  children,
  actionPrimary,
  actionSecondary,
  handlePrimary,
  handleSecondary,
  onClose,
  partialSelector,
  asyncSelector
}) {
  //definición de parametros en base a selector
  const partialParam = useSelector(partialSelector ? partialSelector : () => {});
  const asyncParam = useSelector(asyncSelector ? asyncSelector : () => {})

  return ReactDOM.createPortal(
    <section className="modalContainer">
      <div className="modal">
        <button className="modal__close" onClick={onClose}>
          <VscChromeClose />
        </button>
        <div className="modal__body">{children}</div>
        <div className="modal__actions">
          {actionPrimary && (
            <button
              className="modal__action"
              onClick={() => handlePrimary(partialParam, asyncParam)}
            >
              {actionPrimary}
            </button>
          )}
          {actionSecondary && (
            <button className="modal__action" onClick={handleSecondary}>
              {actionSecondary}
            </button>
          )}
        </div>
      </div>
    </section>,
    document.querySelector("#modal")
  );
}

export default Modal;
