import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockHistoryAsync,
  setInputChoice,
  showHistory,
  showInputChoice,
  showNewInvoice,
} from "../../../features/stockSlice";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";

function InputChoice() {
  const dispatch = useDispatch();

  const [input, setInput] = useState("Elige una remisión");
  const [inputSearch, setInputSearch] = useState("");
  const newInvoice = useSelector(showNewInvoice);

  const inputs = useSelector(showHistory);

  useEffect(() => {
    dispatch(getStockHistoryAsync(inputSearch));
  }, [inputSearch, dispatch]);

  const setInputId = (id) => {
    const choice = inputs.find((item) => item.id === id);
    dispatch(setInputChoice(choice));
  };

  return (
    <>
      <Form>
        <FormDrop
          title="Remisión"
          placeholder={input}
          searchPlaceholder="Código de remisión o cotización"
          searchHandler={setInputSearch}
          searchType="submit"
          handler={setInputId}
          placeholderHandler={setInput}
          data={inputs.filter(
            (item) =>
              item.invoiceId == null &&
              item.incomes[0].supplierId === newInvoice.supplierId &&
              !newInvoice.inputs.some(input => input.id === item.id)
          )}
          dataProps={["id"]}
          dataConector={[""]}
        ></FormDrop>
      </Form>
    </>
  );
}

export default InputChoice;
