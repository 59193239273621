import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getInvoicesAsync,
  getQualificationAsync,
  getStockAsync,
  getStockHistoryAsync,
  showHistory,
  showInvoices,
  showNewInput,
  showQualification,
  showReferences,
  setNewInput,
  getSuppliersAsync,
  getReferenceAsync,
  setNewIncome,
  getStockIdAsync,
  postInput,
  postIncome,
  getStockReferenceIdAsync,
  setTransferQuantity,
  setNewCost,
  getCostAsync,
  showReference,
  setEditReference,
  patchStockSyncReference,
  showEditReference,
  showNewInvoice,
  showAuxInvoices,
  setNewInvoice,
  setInputChoice,
  postInvoicesAsync,
  getInvoicesIdAsync,
  deleteInvoicesAsync,
  setNewMagnitude,
  getDocTypeAsync,
  changeNewSup,
  changeNewRef,
  getUnityAsync,
  showReferenceFilter,
  setReferenceFilter,
  showInputFilter,
  setInputFilter,
  setInvoiceFilter,
  showInvoiceFilter,
} from "../../features/stockSlice";
import HeaderActions from "../HeaderActions/HeaderActions";
import HeaderButton from "../HeaderActions/HeaderButton/HeaderButton";
import HeaderSelect from "../HeaderActions/HeaderSelect/HeaderSelect";
import SearchBar from "../SearchBar/SearchBar";
import Table from "../Table/Table";
import { formaterNumbertoPrice } from "../../utils/functions/reusableFunctions";
import Modal from "../Modal/Modal";
import NewInputPartial from "./modals/NewInputPartial";
import Error from "../Error/Error";
import NewInputAccum from "./modals/NewInputAccum/NewInputAccum";
import {
  defaultEditReference,
  defaultModalState,
  defaultNewCost,
  defaultNewIncome,
  defaultNewInput,
  defaultNewInvoice,
  defaultNewRef,
  defaultNewSup,
  generalFilterInit,
  referencesFilterInit,
} from "./defaultValues";
import Decision from "../Decision/Decision";
import InputInfo from "./modals/InputInfo/InputInfo";
import { useAuth } from "../auth";
import ReferenceInfo from "./modals/ReferenceInfo/ReferenceInfo";
import ReferenceCrud from "./modals/ReferenceCrud";
import NewInvoicePartial from "./modals/NewInvoicePartial";
import NewInvoiceAccum from "./modals/NewInvoiceAccum";
import InvoiceInfo from "./modals/InvoiceInfo";
import ReferenceFilter from "./modals/ReferenceFilter";
import GeneralFilter from "./modals/GeneralFilter";

function StockView() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { authState } = useAuth();

  //Selectors
  const qualifications = useSelector(showQualification);
  const references = useSelector(showReferences);
  const inputs = useSelector(showHistory);
  const invoices = useSelector(showInvoices);

  //Filter States
  const [referencesFilter, setReferencesFilter] =
    useState(referencesFilterInit);

  const [historyFilter, setHistoryFilter] = useState(generalFilterInit);

  const [invoicesFilter, setInvoicesFilter] = useState(generalFilterInit);

  const refFilter = useSelector(showReferenceFilter);
  const inputFilter = useSelector(showInputFilter);
  const invoiceFilter = useSelector(showInvoiceFilter);

  //Modal Controller State

  const [modal, setModal] = useState({
    partial: defaultModalState,
    accum: defaultModalState,
    crudForm: defaultModalState,
    decision: defaultModalState,
  });

  //Process States

  //filter methods
  const applyFilter = (type, refFilter) => {
    console.log(refFilter);
    switch (type) {
      case "reference":
        setReferencesFilter((prev) => ({
          ...prev,
          outstock: refFilter.outstock,
          overstock: refFilter.overstock,
        }));
        closeModal("partial");
        break;
      default:
        break;
    }
  };

  const resetFilter = (type) => {
    switch (type) {
      case "reference":
        dispatch(
          setReferenceFilter({
            ...refFilter,
            outstock: false,
            overstock: false,
          })
        );
        setReferencesFilter((prev) => ({
          ...prev,
          outstock: false,
          overstock: false,
        }));
        break;
      case "input":
        dispatch(setInputFilter({ ...inputFilter, date: null }));
        break;
      case "invoice":
        dispatch(setInvoiceFilter({ ...invoiceFilter, date: null }));
        break;
      default:
        break;
    }
    closeModal("partial");
  };

  useEffect(() => {
    setHistoryFilter((prev) => ({ ...prev, date: inputFilter.date }));
  }, [inputFilter]);

  useEffect(() => {
    setInvoicesFilter((prev) => ({ ...prev, date: invoiceFilter.date }));
  }, [invoiceFilter]);

  //transición de modales

  const nextNewInput = (newInput, inputs) => {
    if (
      newInput.id === "" ||
      newInput.quotation === "" ||
      !newInput.supplierId
    ) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (inputs.length > 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.alreadyExists} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        partial: defaultModalState,
        accum: {
          isActive: true,
          actionPrimary: "Guardar",
          actionSecondary: "Cancelar",
          handlePrimary: saveInput,
          handleSecondary: () => closeModal("accum", "newInput"),
          partialSelector: showNewInput,
          asyncSelector: showHistory,
          content: (
            <NewInputAccum setModal={setModal} closeModal={closeModal} />
          ),
          onClose: () => closeModal("accum", "newInput"),
          errors: {
            ...prev.accum.errors,
            isEmpty:
              "Debes añadir al menos un elemento para poder hacer la entrada",
          },
        },
      }));
    }
  };

  //metodos para guardar entrada
  const saveInput = (newInput) => {
    if (newInput.incomes.length === 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.accum.errors.isEmpty} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro? Esta operación es irreversible" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => saveInputFinal(newInput),
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  const saveInputFinal = (newInput) => {
    //save input in case of fullfilled information (im going to continue it after do the history detail section)
    const { supplierId, supplier, incomes, ...finalInput } = newInput;
    const finalIncomes = incomes.map((item) => {
      const { referenceText, referenceMainId, ...finalIncome } = item;
      return {
        ...finalIncome,
        supplierId: supplierId,
        inputId: finalInput.id,
        supplierCode: finalIncome.reentry ? " " : finalIncome.supplierCode,
        value: finalIncome.value * 1,
      };
    });
    dispatch(postInput({ ...finalInput, userId: authState.user.id }));
    dispatch(postIncome(finalIncomes));
    closeModal("decision");
    closeModal("accum", "newInputYes");
    const { searchValue, qualificationId, overstock, outstock } = refFilter;
    setTimeout(() => {
      dispatch(
        getStockAsync(searchValue, qualificationId, overstock, outstock)
      );
      dispatch(getStockHistoryAsync());
    }, 500);
  };

  //metodos de referencia

  const openEditReference = (reference) => {
    const {
      description,
      magnitude,
      unityId,
      qualificationId,
      origin,
      minLimit,
      maxLimit,
      unityWeight,
    } = reference;
    dispatch(
      setEditReference({
        description,
        magnitude,
        unityId,
        qualificationId,
        origin,
        minLimit,
        maxLimit,
        unityWeight,
      })
    );
    setModal((prev) => ({
      ...prev,
      crudForm: {
        isActive: true,
        actionPrimary: "Guardar",
        handlePrimary: updateReference,
        actionSecondary: "Cancelar",
        handleSecondary: () => closeModal("crudForm", "updateReference"),
        partialSelector: showEditReference,
        asyncSelector: showReference,
        content: <ReferenceCrud isNew={false} setModal={setModal} />,
        onClose: () => closeModal("crudForm", "updateReference"),
        errors: {
          ...prev.crudForm.errors,
          maxDiff: "El límite máximo debe ser mayor al límite mínimo",
        },
      },
    }));
  };

  const openDeleteReference = (id) => {
    setModal((prev) => ({
      ...prev,
      decision: {
        isActive: true,
        content: (
          <Decision mssg="¿Estás seguro que deseas eliminar esta referencia?" />
        ),
        actionPrimary: "Sí",
        handlePrimary: () => {
          dispatch(patchStockSyncReference(id, { enabled: false }));
          const { searchValue, qualificationId, overstock, outstock } =
            refFilter;
          setTimeout(() => {
            dispatch(
              getStockAsync(searchValue, qualificationId, overstock, outstock)
            );
          }, 200);
          closeModal("decision");
          closeModal("crudForm", "updateReference");
          closeModal("accum", "referenceInfo");
        },
        actionSecondary: "No",
        handleSecondary: () => closeModal("decision"),
        onClose: () => closeModal("decision"),
      },
    }));
  };

  const updateReference = (editReference, reference) => {
    const { minLimit, maxLimit, unityWeight, ...data } = editReference;
    const newEditReference = data;
    if (minLimit) newEditReference.minLimit = minLimit * 1;
    if (maxLimit) newEditReference.maxLimit = maxLimit * 1;
    if (unityWeight) newEditReference.unityWeight = unityWeight;

    if (
      newEditReference?.maxLimit < newEditReference?.minLimit &&
      newEditReference?.maxLimit !== 0
    ) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.crudForm.errors.maxDiff} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (!data.description || !data.magnitude || data.magnitude === 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.crudForm.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro que deseas actualizar esta referencia?" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => {
            dispatch(patchStockSyncReference(reference.id, newEditReference));
            const { searchValue, qualificationId, overstock, outstock } =
              refFilter;
            setTimeout(() => {
              dispatch(getStockReferenceIdAsync(reference.id));
              dispatch(
                getStockAsync(searchValue, qualificationId, overstock, outstock)
              );
            }, 200);
            closeModal("decision");
            closeModal("crudForm", "updateReference");
          },
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  //facturación
  const nextNewInvoice = (newInvoice, invoices) => {
    if (newInvoice.id === "" || !newInvoice.supplierId) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (invoices.length > 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.alreadyExists} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        partial: defaultModalState,
        accum: {
          isActive: true,
          actionPrimary: "Guardar",
          actionSecondary: "Cancelar",
          handlePrimary: saveInvoice,
          handleSecondary: () => closeModal("accum", "newInvoice"),
          partialSelector: showNewInvoice,
          asyncSelector: showAuxInvoices,
          content: (
            <NewInvoiceAccum setModal={setModal} closeModal={closeModal} />
          ),
          onClose: () => closeModal("accum", "newInvoice"),
          errors: {
            ...prev.accum.errors,
            isEmpty:
              "Debes añadir al menos un elemento para poder hacer la entrada",
          },
        },
      }));
    }
  };

  const saveInvoice = (newInvoice, invoices) => {
    const { supplier, ...invoice } = newInvoice;
    if (invoice.inputs.length === 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.accum.errors.isEmpty} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      invoice.userId = authState.user.id;
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro que deseas crear esta factura?" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => {
            dispatch(postInvoicesAsync(invoice));
            const { searchValue, date } = invoiceFilter;
            setTimeout(() => {
              dispatch(getInvoicesAsync(searchValue, date));
              dispatch(getStockHistoryAsync());
            }, 200);
            closeModal("decision");
            closeModal("accum", "newInvoiceYes");
          },
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  // const openEditInvoice = (invoice) => {
  //   dispatch(setNewInvoice(invoice));
  //   setModal((prev) => ({
  //     ...prev,
  //     accum: {
  //       isActive: true,
  //       actionPrimary: "Guardar",
  //       actionSecondary: "Cancelar",
  //       handlePrimary: editInvoice,
  //       handleSecondary: () => closeModal("accum", "newInvoice"),
  //       partialSelector: showNewInvoice,
  //       asyncSelector: showAuxInvoices,
  //       content: (
  //         <NewInvoiceAccum isNew={false} setModal={setModal} closeModal={closeModal} />
  //       ),
  //       onClose: () => closeModal("accum", "newInvoice"),
  //       errors: {
  //         ...prev.accum.errors,
  //         isEmpty:
  //           "Debes añadir al menos un elemento para poder hacer la entrada",
  //       },
  //     },
  //   }));
  // };

  // const editInvoice = (newInvoice) => {

  // }

  const openDeleteInvoice = (id) => {
    setModal((prev) => ({
      ...prev,
      decision: {
        isActive: true,
        content: (
          <Decision mssg="¿Estás seguro que deseas eliminar esta factura?" />
        ),
        actionPrimary: "Sí",
        handlePrimary: () => {
          dispatch(deleteInvoicesAsync(id));
          const { searchValue, date } = invoiceFilter;

          setTimeout(() => {
            dispatch(getInvoicesAsync(searchValue, date));
            dispatch(getStockHistoryAsync());
          }, 200);
          closeModal("decision");
          closeModal("accum", "invoiceInfo");
        },
        actionSecondary: "No",
        handleSecondary: () => closeModal("decision"),
        onClose: () => closeModal("decision"),
      },
    }));
  };

  //cerrar modal sin guardar
  const closeModal = (type, process = null) => {
    if (process !== "newInput" && process !== "newInvoice")
      setModal((prev) => ({ ...prev, [type]: defaultModalState }));
    switch (process) {
      case "newInput":
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: (
              <Decision mssg="¿Estás seguro que deseas cancelar la operación?" />
            ),
            actionPrimary: "Si",
            handlePrimary: () => {
              closeModal("decision");
              closeModal("accum", "newInputYes");
            },
            actionSecondary: "No",
            handleSecondary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
        break;
      case "newInputYes":
        dispatch(setNewInput(defaultNewInput));
        break;
      case "newIncome":
        dispatch(setNewIncome(defaultNewIncome));
        dispatch(getStockIdAsync("xxx"));
        break;
      case "transfer":
        dispatch(setTransferQuantity(null));
        break;
      case "updateCosts":
        dispatch(setNewCost(defaultNewCost));
        dispatch(getCostAsync());
        break;
      case "updateRerence":
        dispatch(setEditReference(defaultEditReference));
        dispatch(changeNewRef(defaultNewRef));
        break;
      case "newInvoice":
        setModal((prev) => ({
          ...prev,
          decision: {
            isActive: true,
            content: (
              <Decision mssg="¿Estás seguro que deseas cancelar la operación?" />
            ),
            actionPrimary: "Si",
            handlePrimary: () => {
              closeModal("decision");
              closeModal("accum", "newInvoiceYes");
            },
            actionSecondary: "No",
            handleSecondary: () => closeModal("decision"),
            onClose: () => closeModal("decision"),
          },
        }));
        break;
      case "newInvoiceYes":
        dispatch(setNewInvoice(defaultNewInvoice));
        break;
      case "addInput":
        dispatch(setInputChoice(null));
        break;
      case "newMagnitude":
        dispatch(setNewMagnitude(null));
        break;
      case "newSup":
        dispatch(changeNewSup(defaultNewSup));
        break;
      case "newReference":
        dispatch(setEditReference(defaultEditReference));
        dispatch(changeNewRef(defaultNewRef));
        break;
      case "referenceFilter":
        dispatch(
          setReferenceFilter({
            ...refFilter,
            outstock: referencesFilter.outstock,
            overstock: referencesFilter.overstock,
          })
        );
        break;
      default:
        break;
    }
  };

  //Carga de info de db
  useEffect(() => {
    dispatch(getQualificationAsync());
    dispatch(getSuppliersAsync());
    dispatch(getReferenceAsync());
    dispatch(getStockHistoryAsync());
    dispatch(getDocTypeAsync());
    dispatch(getUnityAsync());
    setReferencesFilter({ ...referencesFilterInit, searchValue: "" });
    dispatch(setReferenceFilter(referencesFilterInit));
    setHistoryFilter({ ...generalFilterInit, searchValue: "" });
    dispatch(setInputFilter(generalFilterInit));
    setInvoicesFilter({ ...generalFilterInit, searchValue: "" });
    dispatch(setInvoiceFilter(generalFilterInit));
  }, [dispatch, slug]);

  //Inicialización de variables aux para tablas
  let columns = [];

  //Despachadores de filtros cuando detectan cambios
  useEffect(() => {
    const { searchValue, qualificationId, overstock, outstock } =
      referencesFilter;
    dispatch(setReferenceFilter(referencesFilter));
    dispatch(getStockAsync(searchValue, qualificationId, overstock, outstock));
  }, [referencesFilter, dispatch]);

  useEffect(() => {
    if (slug === "history") {
      const { searchValue, date } = historyFilter;
      setTimeout(() => {
        dispatch(getStockHistoryAsync(searchValue, null, date));
      }, 10);
    } else if (slug === "invoices") {
      const { searchValue, date } = invoicesFilter;
      setTimeout(() => {
        dispatch(getInvoicesAsync(searchValue, date));
      }, 10);
    }
  }, [historyFilter, invoicesFilter, dispatch]);

  //Switch de renderizado dependiendo de la sub ruta
  switch (slug) {
    case "references":
      columns = [
        "Código",
        "Referencia",
        "Medida",
        "Cantidad",
        "Costo",
        "Valor Total",
      ];
      return (
        <div className="viewSection">
          <HeaderActions>
            {/* type? -> Cuando ejecutar el handleEvent
            handleEvent -> Función capturadora del valor
            property? -> En caso de que la capturadora deba setear determinada propiedad de un objeto
            reset -> Estado que al cambiar resetea el valor 
            location? -> Ubicación, form si es en formulario*/}
            <SearchBar
              type="submit"
              placeholder="Código, referencia o código de proveedor"
              handleEvent={setReferencesFilter}
              property="searchValue"
              reset={slug}
            />
            <HeaderButton
              handler={() => {
                setModal((prev) => ({
                  ...prev,
                  partial: {
                    isActive: true,
                    actionPrimary: "Aplicar",
                    actionSecondary: "Limpiar Filtros",
                    handlePrimary: (refFilter) =>
                      applyFilter("reference", refFilter),
                    handleSecondary: () => resetFilter("reference"),
                    partialSelector: showReferenceFilter,
                    content: <ReferenceFilter />,
                    onClose: () => closeModal("partial", "referenceFilter"),
                  },
                }));
              }}
            >
              <span>Filtro</span>
              {(referencesFilter.outstock || referencesFilter.overstock) && (
                <div className="filterCounter">1</div>
              )}
            </HeaderButton>
            <HeaderButton
              handler={() => {
                setModal((prev) => ({
                  ...prev,
                  partial: {
                    isActive: true,
                    actionPrimary: "Aceptar",
                    handlePrimary: nextNewInput,
                    partialSelector: showNewInput,
                    asyncSelector: showHistory,
                    content: (
                      <NewInputPartial
                        setModal={setModal}
                        closeModal={closeModal}
                      />
                    ),
                    onClose: () => closeModal("partial", "newInputYes"),
                    errors: {
                      ...prev.partial.errors,
                      alreadyExists:
                        "Ya existe una remisión con el mismo código que ingresaste",
                    },
                  },
                }));
              }}
            >
              Añadir Entrada de Inventario
            </HeaderButton>
            <HeaderButton
              handler={() => {
                dispatch(getStockHistoryAsync());
                setModal((prev) => ({
                  ...prev,
                  accum: {
                    isActive: true,
                    actionPrimary: "Guardar",
                    actionSecondary: "Cancelar",
                    handlePrimary: saveInput,
                    handleSecondary: () => closeModal("accum", "newInput"),
                    partialSelector: showNewInput,
                    asyncSelector: showHistory,
                    content: (
                      <NewInputAccum
                        reentry={true}
                        setModal={setModal}
                        closeModal={closeModal}
                      />
                    ),
                    onClose: () => closeModal("accum", "newInput"),
                    errors: {
                      ...prev.accum.errors,
                      isEmpty:
                        "Debes añadir al menos un elemento para poder hacer la entrada",
                    },
                  },
                }));
              }}
            >
              Añadir Reentrada
            </HeaderButton>
            <HeaderButton>
              <Link to="/stock/history">Historial de Entradas</Link>
            </HeaderButton>
            <HeaderButton>
              <Link to="/stock/invoices">Facturas</Link>
            </HeaderButton>
            <HeaderSelect
              setFilter={setReferencesFilter}
              defaultOption="Clasificación"
              propertyName="qualification"
              options={qualifications}
            />
          </HeaderActions>
          <Table columns={columns}>
            <tbody className="table__body">
              {references.map((ref) => (
                <tr
                  key={ref.id}
                  onClick={() => {
                    dispatch(getStockReferenceIdAsync(ref.id));
                    setTimeout(() => {
                      setModal((prev) => ({
                        ...prev,
                        accum: {
                          isActive: true,
                          actionPrimary: "Editar",
                          actionSecondary: "Eliminar",
                          handlePrimary: openEditReference,
                          handleSecondary: () => openDeleteReference(ref.id),
                          partialSelector: showReference,
                          // asyncSelector: showHistory,
                          content: (
                            <ReferenceInfo
                              setModal={setModal}
                              closeModal={closeModal}
                            />
                          ),
                          onClose: () => closeModal("accum", "referenceInfo"),
                          errors: {
                            ...prev.accum.errors,
                          },
                        },
                      }));
                    }, 300);
                  }}
                >
                  <td>{ref.id}</td>
                  <td>{ref.description}</td>
                  <td>{`x${ref.magnitude} ${ref.unity.unity}`}</td>
                  <td>{ref.balance}</td>
                  <td>{formaterNumbertoPrice(ref.cost)}</td>
                  <td>{formaterNumbertoPrice(ref.cost * ref.balance)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {references.length === 0 && (
            <p className="modal__text modal__empty">No hay registros</p>
          )}
          {modal.partial.isActive && (
            <Modal
              actionPrimary={modal.partial.actionPrimary}
              actionSecondary={modal.partial.actionSecondary}
              handlePrimary={modal.partial.handlePrimary}
              handleSecondary={modal.partial.handleSecondary}
              onClose={modal.partial.onClose}
              partialSelector={modal.partial.partialSelector}
              asyncSelector={modal.partial.asyncSelector}
            >
              {modal.partial.content}
            </Modal>
          )}
          {modal.accum.isActive && (
            <Modal
              actionPrimary={modal.accum.actionPrimary}
              actionSecondary={modal.accum.actionSecondary}
              handlePrimary={modal.accum.handlePrimary}
              handleSecondary={modal.accum.handleSecondary}
              onClose={modal.accum.onClose}
              partialSelector={modal.accum.partialSelector}
              asyncSelector={modal.accum.asyncSelector}
            >
              {modal.accum.content}
            </Modal>
          )}
          {modal.crudForm.isActive && (
            <Modal
              actionPrimary={modal.crudForm.actionPrimary}
              actionSecondary={modal.crudForm.actionSecondary}
              handlePrimary={modal.crudForm.handlePrimary}
              handleSecondary={modal.crudForm.handleSecondary}
              onClose={modal.crudForm.onClose}
              partialSelector={modal.crudForm.partialSelector}
              asyncSelector={modal.crudForm.asyncSelector}
            >
              {modal.crudForm.content}
            </Modal>
          )}
          {modal.decision.isActive && (
            <Modal
              actionPrimary={modal.decision.actionPrimary}
              actionSecondary={modal.decision.actionSecondary}
              handlePrimary={modal.decision.handlePrimary}
              handleSecondary={modal.decision.handleSecondary}
              onClose={modal.decision.onClose}
            >
              {modal.decision.content}
            </Modal>
          )}
        </div>
      );
    case "history":
      columns = [
        "Remisión",
        "Cotización",
        "Proveedor",
        "Cantidad de elementos",
        "Costo Total",
        "Fecha",
        "Factura",
      ];
      return (
        <div className="viewSection">
          <HeaderActions>
            <SearchBar
              placeholder="Remisión, cotización o factura"
              type="submit"
              handleEvent={setHistoryFilter}
              property="searchValue"
              reset={slug}
            />
            <HeaderButton
              handler={() => {
                setModal((prev) => ({
                  ...prev,
                  partial: {
                    isActive: true,
                    actionSecondary: "Limpiar Filtros",
                    handleSecondary: () => resetFilter("input"),
                    partialSelector: showInputFilter,
                    content: (
                      <GeneralFilter closeModal={closeModal} module="input" />
                    ),
                    onClose: () => closeModal("partial", "inputFilter"),
                  },
                }));
              }}
            >
              {inputFilter.date ? inputFilter.date : "Filtro"}
            </HeaderButton>
            <HeaderButton>
              <Link to="/stock/references">Volver a las referencias</Link>
            </HeaderButton>
          </HeaderActions>
          <Table columns={columns}>
            <tbody className="table__body">
              {inputs.map((input) => (
                <tr
                  key={input.id}
                  onClick={() => {
                    setTimeout(() => {
                      setModal((prev) => ({
                        ...prev,
                        accum: {
                          isActive: true,
                          content: (
                            <InputInfo
                              input={input}
                              setModal={setModal}
                              closeModal={closeModal}
                            />
                          ),
                          onClose: () => closeModal("accum", "InputInfo"),
                        },
                      }));
                    }, 300);
                  }}
                >
                  <td>{input.id}</td>
                  <td>{input.quotation}</td>
                  <td>{input.incomes[0].supplier.fullName}</td>
                  <td>{input.incomesQuantity}</td>

                  <td>
                    {input.id.substr(0, 2) !== "CR"
                      ? formaterNumbertoPrice(input.totalCost)
                      : "No Aplica"}
                  </td>
                  <td>{input.date}</td>
                  <td>
                    {input.id.substr(0, 2) !== "CR"
                      ? input.invoiceId
                      : "No Aplica"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {inputs.length === 0 && (
            <p className="modal__text modal__empty">No hay registros</p>
          )}
          {modal.partial.isActive && (
            <Modal
              actionPrimary={modal.partial.actionPrimary}
              actionSecondary={modal.partial.actionSecondary}
              handlePrimary={modal.partial.handlePrimary}
              handleSecondary={modal.partial.handleSecondary}
              onClose={modal.partial.onClose}
              partialSelector={modal.partial.partialSelector}
              asyncSelector={modal.partial.asyncSelector}
            >
              {modal.partial.content}
            </Modal>
          )}
          {modal.accum.isActive && (
            <Modal
              actionPrimary={modal.accum.actionPrimary}
              actionSecondary={modal.accum.actionSecondary}
              handlePrimary={modal.accum.handlePrimary}
              handleSecondary={modal.accum.handleSecondary}
              onClose={modal.accum.onClose}
              partialSelector={modal.accum.partialSelector}
              asyncSelector={modal.accum.asyncSelector}
            >
              {modal.accum.content}
            </Modal>
          )}
        </div>
      );
    case "invoices":
      columns = [
        "Factura",
        "Proveedor",
        "Cantidad de remisiones",
        "Costo Total",
        "Fecha",
      ];
      return (
        <div className="viewSection">
          <HeaderActions>
            <SearchBar
              placeholder="Factura o proveedor"
              type="submit"
              handleEvent={setInvoicesFilter}
              property="searchValue"
              reset={slug}
            />
            <HeaderButton
              handler={() => {
                setModal((prev) => ({
                  ...prev,
                  partial: {
                    isActive: true,
                    actionSecondary: "Limpiar Filtros",
                    handleSecondary: () => resetFilter("invoice"),
                    partialSelector: showInvoiceFilter,
                    content: (
                      <GeneralFilter closeModal={closeModal} module="invoice" />
                    ),
                    onClose: () => closeModal("partial", "invoiceFilter"),
                  },
                }));
              }}
            >
              {invoiceFilter.date ? invoiceFilter.date : "Filtro"}
            </HeaderButton>
            <HeaderButton
              handler={() => {
                setModal((prev) => ({
                  ...prev,
                  partial: {
                    isActive: true,
                    actionPrimary: "Aceptar",
                    handlePrimary: nextNewInvoice,
                    partialSelector: showNewInvoice,
                    asyncSelector: showAuxInvoices,
                    content: <NewInvoicePartial />,
                    onClose: () => closeModal("partial", "newInvoiceYes"),
                    errors: {
                      ...prev.partial.errors,
                      alreadyExists:
                        "Ya existe una factura con el mismo código que ingresaste",
                    },
                  },
                }));
              }}
            >
              Nueva Factura
            </HeaderButton>
            <HeaderButton>
              <Link to="/stock/references">Volver a las referencias</Link>
            </HeaderButton>
          </HeaderActions>
          <Table columns={columns}>
            <tbody className="table__body">
              {invoices.map((invoice) => (
                <tr
                  key={invoice.id}
                  onClick={() => {
                    dispatch(getInvoicesIdAsync(invoice.id));
                    setTimeout(() => {
                      setModal((prev) => ({
                        ...prev,
                        accum: {
                          isActive: true,
                          // actionPrimary: "Editar",
                          // handlePrimary: openEditInvoice,
                          actionSecondary: "Eliminar",
                          // partialSelector: showInvoice,
                          handleSecondary: () => openDeleteInvoice(invoice.id),
                          content: (
                            <InvoiceInfo
                              setModal={setModal}
                              closeModal={closeModal}
                            />
                          ),
                          onClose: () => closeModal("accum", "invoiceInfo"),
                        },
                      }));
                    }, 300);
                  }}
                >
                  <td>{invoice.id}</td>
                  <td>{invoice.supplier.fullName}</td>
                  <td>{invoice.inputsQuantity}</td>
                  <td>{formaterNumbertoPrice(invoice.total)}</td>
                  <td>{invoice.date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {invoices.length === 0 && (
            <p className="modal__text modal__empty">No hay registros</p>
          )}
          {modal.partial.isActive && (
            <Modal
              actionPrimary={modal.partial.actionPrimary}
              actionSecondary={modal.partial.actionSecondary}
              handlePrimary={modal.partial.handlePrimary}
              handleSecondary={modal.partial.handleSecondary}
              onClose={modal.partial.onClose}
              partialSelector={modal.partial.partialSelector}
              asyncSelector={modal.partial.asyncSelector}
            >
              {modal.partial.content}
            </Modal>
          )}
          {modal.accum.isActive && (
            <Modal
              actionPrimary={modal.accum.actionPrimary}
              actionSecondary={modal.accum.actionSecondary}
              handlePrimary={modal.accum.handlePrimary}
              handleSecondary={modal.accum.handleSecondary}
              onClose={modal.accum.onClose}
              partialSelector={modal.accum.partialSelector}
              asyncSelector={modal.accum.asyncSelector}
            >
              {modal.accum.content}
            </Modal>
          )}
          {modal.crudForm.isActive && (
            <Modal
              actionPrimary={modal.crudForm.actionPrimary}
              actionSecondary={modal.crudForm.actionSecondary}
              handlePrimary={modal.crudForm.handlePrimary}
              handleSecondary={modal.crudForm.handleSecondary}
              onClose={modal.crudForm.onClose}
              partialSelector={modal.crudForm.partialSelector}
              asyncSelector={modal.crudForm.asyncSelector}
            >
              {modal.crudForm.content}
            </Modal>
          )}
          {modal.decision.isActive && (
            <Modal
              actionPrimary={modal.decision.actionPrimary}
              actionSecondary={modal.decision.actionSecondary}
              handlePrimary={modal.decision.handlePrimary}
              handleSecondary={modal.decision.handleSecondary}
              onClose={modal.decision.onClose}
            >
              {modal.decision.content}
            </Modal>
          )}
        </div>
      );
    default:
      break;
  }
}

export default StockView;
