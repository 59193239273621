import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./SearchBar.css";

function SearchBar({
  placeholder,
  type,
  handleEvent,
  property,
  reset,
  location,
}) {
  const [value, setValue] = useState("");

  const isObject = () => {
    return property ? (prev) => ({ ...prev, [property]: value }) : value;
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    
    if (type && type === "submit") {
      if (e.keyCode === 13) handleEvent(isObject());
    } else {
      handleEvent(isObject());
    }
  };

  useEffect(() => {
    setValue("");
    handleEvent("");
  }, [reset]);

  return (
    <div
      className={
        location === "form"
          ? "searchBar searchBar--form"
          : "searchBar searchBar--main"
      }
    >
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onKeyUp={handleChange}
        onChange={handleChange}
      />
      <svg
        aria-hidden="true"
        className="w-5 h-5 searchBar__svg"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
      </svg>
    </div>
  );
}

export default SearchBar;
