import React, { useEffect, useMemo, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  getCostAsync,
  getReferenceAsync,
  getStockAsync,
  getStockIdAsync,
  postReference,
  setNewIncome,
  setNewMagnitude,
  showCost,
  showNewIncome,
  showNewInput,
  showNewMagnitude,
  showNewRef,
  showRef,
  showReference,
  showReferenceFilter,
} from "../../../features/stockSlice";
import Decision from "../../Decision/Decision";
import Error from "../../Error/Error";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";
import ReferenceCrud from "./ReferenceCrud";

function NewIncome({ reentry, setModal, closeModal }) {
  const [isChecked, setIsChecked] = useState(false);
  const [reference, setReference] = useState("Elige una referencia");
  const [referenceSearch, setReferenceSearch] = useState("");
  const [unity, setUnity] = useState("Elige una medida");
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const refFilter = useSelector(showReferenceFilter);
  const newInput = useSelector(showNewInput);
  const newIncome = useSelector(showNewIncome);
  const references = useSelector(showRef);
  const referenceData = useSelector(showReference);
  const cost = useSelector(showCost);
  const newMagnitude = useSelector(showNewMagnitude);

  useMemo(() => {
    if (reentry) {
      dispatch(
        setNewIncome({
          ...newIncome,
          reentry,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      setNewIncome({
        ...newIncome,
        reentry: isChecked,
        supplierId: isChecked ? 1 : null,
        value: isChecked ? "" : newIncome.value,
        supplierCode: "",
      })
    );

    if (isChecked) {
    } else {
      dispatch(
        getCostAsync(
          newIncome.referenceMainId ? newIncome.referenceMainId : "xxx",
          newInput.supplierId
        )
      );
    }
  }, [isChecked, dispatch]);

  //test effect
  useEffect(() => {
    console.log(newIncome);
  }, [newIncome]);

  useEffect(() => {
    dispatch(getReferenceAsync(referenceSearch));
  }, [referenceSearch, dispatch]);

  useEffect(() => {
    dispatch(
      setNewIncome({
        ...newIncome,
        referenceMainId: referenceData?.id,
        referenceId: null,
        referenceText: null,
      })
    );
  }, [referenceData, dispatch]);

  useEffect(() => {
    const selectedCost = cost[cost.length - 1];
    const spCode = selectedCost?.supplierCode;
    const spValue = selectedCost?.cost;
    dispatch(
      setNewIncome({
        ...newIncome,
        supplierCode: spCode ? spCode : "",
        value: spValue ? spValue : "",
      })
    );
  }, [cost, dispatch]);

  const setReferenceId = (id) => {
    if (id === "CX-1") {
      dispatch(
        setNewIncome({
          ...newIncome,
          referenceMainId: id,
          referenceId: id,
          supplierCode: "",
          referenceText: `Chatarra x1 kg`,
        })
      );
      setIsChecked(true);
      setDisabled(true);
      setUnity("x1 kg");
    } else {
      dispatch(getStockIdAsync(id));
      dispatch(getCostAsync(!isChecked ? id : "xxx", newInput.supplierId));
      setDisabled(false);
      setUnity("Elige una medida");
    }
  };

  const setUnityReferenceId = (id) => {
    const magnitude = referenceData.magnitudes.find((item) => item.id === id);
    dispatch(
      setNewIncome({
        ...newIncome,
        referenceId: id,
        referenceText: `${referenceData.description} x${magnitude.magnitude} ${referenceData.unity.unity}`,
      })
    );
  };

  const setValuesNewIncome = (e, prop) => {
    const { value } = e.target;

    dispatch(
      setNewIncome({
        ...newIncome,
        [prop]: value,
      })
    );
  };

  const addMagnitude = () => {
    setModal((prev) => ({
      ...prev,
      partial: {
        isActive: true,
        actionPrimary: "Aceptar",
        handlePrimary: saveMagnitude,
        partialSelector: showNewMagnitude,
        asyncSelector: showReference,
        content: (
          <>
            <h2 className="modal__title">Nueva medida</h2>
            <Form>
              <div className="input__container">
                <p>
                  Nueva medida <span>*</span>
                </p>
                <input
                  type="number"
                  className="form__input"
                  disabled={isChecked}
                  onChange={(e) => dispatch(setNewMagnitude(e.target.value))}
                />
              </div>
            </Form>
          </>
        ),
        onClose: () => closeModal("partial", "newMagnitude"),
        errors: {
          ...prev.partial.errors,
          alreadyExists: "Ya existe esta medida dentro de esta referencia",
          maxPassed: "La nueva magnitud no puede ser mayor a la original"
        },
      },
    }));
  };

  const saveMagnitude = (newMagnitude, ref) => {
    const alreadyExists = ref.magnitudes.some(
      //no tocar esta comparación cabrones
      (item) => item.magnitude == newMagnitude
    );
    if (alreadyExists) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.alreadyExists} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (!newMagnitude || newMagnitude * 1 === 0) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if(newMagnitude > Math.max(...ref.magnitudes.map(item => item.magnitude)) && reentry) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.maxPassed} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      const { description, unityId, qualificationId, origin } = ref;
      const newRef = {
        description,
        unityId,
        qualificationId,
        origin,
        magnitude: newMagnitude * 1,
      };
      dispatch(postReference(newRef));
      setTimeout(() => {
        dispatch(getStockIdAsync(ref.id));
      }, 300);
      closeModal("partial", "newMagnitude");
    }
  };

  const addReference = () => {
    setModal((prev) => ({
      ...prev,
      partial: {
        isActive: true,
        actionPrimary: "Aceptar",
        handlePrimary: validateReference,
        partialSelector: showNewRef,
        content: <ReferenceCrud />,
        onClose: () => closeModal("partial", "newReference"),
        errors: {
          ...prev.partial.errors,
          maxDiff: "El límite máximo debe ser mayor al límite mínimo",
        },
      },
    }));
  };

  const validateReference = (newRef) => {
    const { description, magnitude, unityId, qualificationId, origin } = newRef;

    const { minLimit, maxLimit, unityWeight, ...data } = newRef;
    const newFinalReference = data;
    if(minLimit) newFinalReference.minLimit = minLimit*1;
    if(maxLimit) newFinalReference.maxLimit = maxLimit*1;
    if(unityWeight) newFinalReference.unityWeight = unityWeight;

    if (
      !description ||
      !magnitude ||
      magnitude * 1 === 0 ||
      !unityId ||
      !qualificationId ||
      !origin
    ) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else if (
      newFinalReference?.maxLimit < newFinalReference?.minLimit &&
      newFinalReference?.maxLimit !== 0
    ) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.maxDiff} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: (
            <Decision mssg="¿Estás seguro que deseas guardar esta referencia?" />
          ),
          actionPrimary: "Sí",
          handlePrimary: () => {
            dispatch(postReference(newFinalReference));
            const { searchValue, qualificationId, overstock, outstock } =
              refFilter;
            setTimeout(() => {
              dispatch(getReferenceAsync(referenceSearch));
              dispatch(getStockAsync(searchValue, qualificationId, overstock, outstock));
            }, 200);
            closeModal("decision");
            closeModal("partial", "newReference");
          },
          actionSecondary: "No",
          handleSecondary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    }
  };

  return (
    <>
      <h2 className="modal__title">Nuevo Ingreso</h2>
      <Form row="true">
        <FormDrop
          title="Referencia"
          placeholder={reference}
          searchPlaceholder="Código, referencia o código de proveedor"
          searchHandler={setReferenceSearch}
          searchType="submit"
          handler={setReferenceId}
          placeholderHandler={setReference}
          data={references.filter((item) => {
            return (
              (!reentry ? item.id !== "CX-1" : true) &&
              !newInput.incomes.some((income) => income.referenceId === item.id)
            );
          })}
          dataProps={["id", "description"]}
          dataConector={["", "=> "]}
        >
          <button
            type="button"
            onClick={() => addReference()}
            className="form__newItem"
          >
            Nueva Referencia
          </button>
        </FormDrop>
        <FormDrop
          title="Medida"
          search={false}
          placeholder={unity}
          handler={setUnityReferenceId}
          placeholderHandler={setUnity}
          data={referenceData?.magnitudes}
          dataProps={["magnitude", "unity"]}
          enabled={!!newIncome.referenceMainId && !disabled}
          dataConector={["x"]}
        >
          <button
            onClick={() => addMagnitude(referenceData)}
            className="form__newItem"
          >
            Nueva medida
          </button>
        </FormDrop>
        {!reentry && (
          <div
            className={
              isChecked
                ? "input__container input__container--disabled"
                : "input__container"
            }
          >
            <p>Código de proveedor</p>
            <input
              type="text"
              value={newIncome.supplierCode}
              className="form__input"
              disabled={isChecked}
              onChange={(e) => setValuesNewIncome(e, "supplierCode")}
            />
          </div>
        )}

        <div className="input__container">
          <p>
            Cantidad <span>*</span>
          </p>
          <input
            type="number"
            className="form__input"
            value={newIncome.quantity}
            onChange={(e) => setValuesNewIncome(e, "quantity")}
          />
        </div>
        {!reentry && (
          <div
            className={
              isChecked
                ? "input__container input__container--disabled"
                : "input__container"
            }
          >
            <p>
              Valor unitario <span>*</span>
            </p>
            <input
              type="number"
              className="form__input"
              disabled={isChecked}
              value={newIncome.value}
              onChange={(e) => setValuesNewIncome(e, "value")}
            />
          </div>
        )}
      </Form>
    </>
  );
}

export default NewIncome;
