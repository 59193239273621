import { useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai"
import { useState } from "react";
import axios from "axios";
import { useAuth } from ".";
import serverUri from "../../config/config";
const URL = serverUri;

export default function Recovery(params) {
  const navigate = useNavigate();
  const auth = useAuth();
  const [error, setError] = useState(false);


  const handleClickToBack = () => {
    navigate(-1)
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const onSubmit = async (data) => {
    try {
      const rta = await axios.post(`${URL}/auth/recovery`, { email: data.email });
      setError(false);
      auth.isExpired(false);
      navigate('/check_your_email');
    } catch (error) {
      setError(true);
    }
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center ">
      <div className="w-full pb-32 mt-[-240px] sm:mt-[-280px] xl:hidden" >
        <button className="mt-6 ml-4" onClick={handleClickToBack}>
          <AiOutlineArrowLeft className="text-green-700 text-3xl" />
        </button>
      </div>
      <section className="">
        <div className="justify-center p-10 rounded-lg justify-center p-10 rounded-lg text-center">
          {auth.authState.recoveryExpired && <p className="text-red-500">Ups, ha pasado demasiado tiempo, vuelve a intentarlo.</p>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center mb-10">
              <h1 className="text-3xl sm:text-5xl xl:text-7xl font-bold text-green-700 mb-5">
                Recupera tu contraseña
              </h1>
              <h2 className="font-semibold sm:text-3xl xl:text-4xl text-slate-600">Ingresa tu correo electrónico</h2>
            </div>
            <div className="mb-6">
              <h3 className="font-semibold mb-2 sm:text-2xl xl:text-3xl">Correo electrónico</h3>
              <input
                name="email"
                type="email"
                className="form-control bg-green-50 border-none block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-slate-100 focus:outline-none"
                id="exampleFormControlInput2"
                placeholder="example@example.com"
                {...register("email", {
                  required: {
                    value: true,
                    message:
                      "Este campo es obligatorio",
                  },
                })}
              />
              <p className="text-red-500">{errors.email?.message}</p>
              {error && <p className="text-red-500">No hemos encontrado ninguna cuenta con este correo.</p>}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="flex justify-center text-center mt-1 rounded-full px-6 xl:px-32 py-2 bg-green-700 text-white font-semibold sm:text-2xl hover:bg-green-400 hover:shadow-lg focus:bg-green-700 focus:outline-none focus:ring-0 active:bg-green-800 transition duration-150 ease-in-out"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
