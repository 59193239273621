import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
//import Home from "./components/home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Recovery from "./components/auth/Recovery";
import CheckYourEmail from "./components/auth/CheckYourEmail";
import AlreadyRegister from "./components/auth/AlreadyRegister";
import PasswordCreated from "./components/auth/PasswordCreated";
import NewPassword from "./components/auth/NewPassword";
import Dashboard from "./components/dashboard/index";
import { AuthGuardian } from "./components/auth";
import Stock from "./components/Stock/index";
import StockView from "./components/Stock/StockView";
import Supplier from "./components/Suppliers/index";

function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/home" element={<Home />}></Route> */}
        <Route
          path="/login"
          element={
            <AuthGuardian>
              <Login />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/register"
          element={
            <AuthGuardian>
              <Register />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/recovery"
          element={
            <AuthGuardian>
              <Recovery />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/new_password"
          element={
            <AuthGuardian>
              <NewPassword />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/check_your_email"
          element={
            <AuthGuardian>
              <CheckYourEmail />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/already_register"
          element={
            <AuthGuardian>
              <AlreadyRegister />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/password_created"
          element={
            <AuthGuardian>
              <PasswordCreated />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <AuthGuardian logged="true">
              <Dashboard />
            </AuthGuardian>
          }
        ></Route>
        <Route
          path="/stock"
          element={
            <AuthGuardian logged="true">
              <Stock />
            </AuthGuardian>
          }
        >
          <Route path=":slug" element={<StockView></StockView>}></Route>
        </Route>
        <Route path="/suppliers" element={<AuthGuardian logged={true}><Supplier /></AuthGuardian>}></Route>
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
