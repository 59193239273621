import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNewRef,
  setEditReference,
  showEditReference,
  showNewRef,
  showQualification,
  showReference,
  showUnits,
} from "../../../features/stockSlice";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";

function ReferenceCrud({ isNew = true, setModal }) {
  const dispatch = useDispatch();
  const reference = useSelector(showReference);
  const editReference = useSelector(showEditReference);
  const unities = useSelector(showUnits);

  const newRef = useSelector(showNewRef);

  const [qualification, setQualification] = useState(
    isNew
      ? "Selecciona una clasificación"
      : reference.qualification.qualification
  );

  const [unity, setUnity] = useState(
    isNew
      ? "Selecciona una unidad"
      : reference.unity.unity
  );

  const [origin, setOrigin] = useState(
    isNew ? "Selecciona un origen" : reference.origin
  );
  const qualifications = useSelector(showQualification);

  const origins = [
    { id: "Nacional", origin: "Nacional" },
    { id: "Importado", origin: "Importado" },
  ];

  //Edit functions

  const setProps = (prop) => (value) => {
    dispatch(
      isNew
        ? changeNewRef({
            ...newRef,
            [prop]: value,
          })
        : setEditReference({
            ...editReference,
            [prop]: value,
          })
    );
  };

  //create functions

  return (
    <>
      <h2 className="modal__title">
        {isNew ? "Nueva Referencia" : `Editar ${reference.description}`}
      </h2>
      <Form row="true">
        {isNew ? (
          <>
            <div className="input__container">
              <p>
                Referencia <span>*</span>
              </p>
              <input
                type="text"
                className="form__input"
                value={newRef.description}
                onChange={(e) => setProps("description")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>
                Magnitud <span>*</span>
              </p>
              <input
                type="number"
                className="form__input"
                value={newRef.magnitude}
                onChange={(e) => setProps("magnitude")(e.target.value)}
              />
            </div>
            <FormDrop
              title="Unidad de medida"
              search={false}
              placeholder={unity}
              handler={setProps("unityId")}
              placeholderHandler={setUnity}
              data={unities}
              dataProps={["description"]}
              dataConector={[""]}
            ></FormDrop>
            <FormDrop
              title="Clasificación"
              search={false}
              placeholder={qualification}
              handler={setProps("qualificationId")}
              placeholderHandler={setQualification}
              data={qualifications}
              dataProps={["qualification"]}
              dataConector={[""]}
            ></FormDrop>
            <FormDrop
              title="Origen"
              search={false}
              placeholder={origin}
              handler={setProps("origin")}
              placeholderHandler={setOrigin}
              data={origins}
              dataProps={["origin"]}
              dataConector={[""]}
            ></FormDrop>
            <div className="input__container">
              <p>Límite Mínimo</p>
              <input
                type="number"
                className="form__input"
                value={newRef.minLimit}
                onChange={(e) => setProps("minLimit")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>Límite Máximo</p>
              <input
                type="number"
                className="form__input"
                value={newRef.maxLimit}
                onChange={(e) => setProps("maxLimit")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>Peso en Kg</p>
              <input
                type="number"
                className="form__input"
                value={newRef.unityWeight}
                onChange={(e) => setProps("unityWeight")(e.target.value)}
              />
            </div>
          </>
        ) : (
          <>
            <div className="input__container">
              <p>Referencia <span>*</span></p>
              <input
                type="text"
                className="form__input"
                value={editReference.description}
                onChange={(e) => setProps("description")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>
                Magnitud <span>*</span>
              </p>
              <input
                type="number"
                className="form__input"
                value={editReference.magnitude}
                onChange={(e) => setProps("magnitude")(e.target.value)}
              />
            </div>
            <FormDrop
              title="Unidad de medida"
              search={false}
              placeholder={unity}
              handler={setProps("unityId")}
              placeholderHandler={setUnity}
              data={unities}
              dataProps={["description"]}
              dataConector={[""]}
            ></FormDrop>
            <FormDrop
              title="Clasificación"
              search={false}
              placeholder={qualification}
              handler={setProps("qualificationId")}
              placeholderHandler={setQualification}
              data={qualifications}
              dataProps={["qualification"]}
              dataConector={[""]}
            ></FormDrop>
            <FormDrop
              title="Origen"
              search={false}
              placeholder={origin}
              handler={setProps("origin")}
              placeholderHandler={setOrigin}
              data={origins}
              dataProps={["origin"]}
              dataConector={[""]}
            ></FormDrop>
            <div className="input__container">
              <p>Límite Mínimo</p>
              <input
                type="number"
                className="form__input"
                value={
                  editReference.minLimit > 0 ? editReference.minLimit : null
                }
                onChange={(e) => setProps("minLimit")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>Límite Máximo</p>
              <input
                type="number"
                className="form__input"
                value={
                  editReference.maxLimit > 0 ? editReference.maxLimit : null
                }
                onChange={(e) => setProps("maxLimit")(e.target.value)}
              />
            </div>
            <div className="input__container">
              <p>Peso en Kg</p>
              <input
                type="number"
                className="form__input"
                value={
                  editReference.unityWeight > 0
                    ? editReference.unityWeight
                    : null
                }
                onChange={(e) => setProps("unityWeight")(e.target.value)}
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
}

export default ReferenceCrud;
