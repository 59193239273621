import React, { useRef, useState, forwardRef } from "react";
import { useSpring, animated } from "react-spring";
import { getDashboardStockAsync, getDashboardReportAsync } from "../../../features/dashboardSlice";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../utils/functions/reusableFunctions";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";

import "./fechas.css";

const ModalFecha = ({ showModal, setShowModal, setDate, setIsFiltered, parent }) => {
  const dispatch = useDispatch();
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const handleClick = (dateInput) => {
    setDate(dateInput);
    switch(parent) {
      case "stock":
        dispatch(getDashboardStockAsync(dateInput));
        break;
      case "report":
        dispatch(getDashboardReportAsync(dateInput));
        break;
      default:
        break;
    }
    setShowModal(false);
    setIsFiltered(true);
    setDate(dateInput);

  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <>
      {showModal ? (
        <ContainerModal ref={modalRef}>
          <animated.div style={animation}>
            <WindowDate showModal={showModal}>
              <ButtonClose onClick={()=>setShowModal(false)}/>
              <Content>
                <DatePicker
                  value="Anual"
                  onChange={(date) =>
                    handleClick(formatDate(date).split("/")[2])
                  }
                  showYearPicker
                  dateFormat="yyyy"
                  placeholderText="Hola"
                  customInput={<ExampleCustomInput />}
                />
                <DatePicker
                  value="Mensual"
                  onChange={(date) =>
                    handleClick(
                      formatDate(date).split("/")[1] +
                        "/" +
                        formatDate(date).split("/")[2]
                    )
                  }
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <DatePicker
                  value="Diario"
                  onChange={(date) => handleClick(formatDate(date))}
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
              </Content>
            </WindowDate>
          </animated.div>
        </ContainerModal>
      ) : null}
    </>
  );
};


export default ModalFecha;

const ButtonClose = styled(IoMdClose)`
  position: absolute;
  font-size: 35px;
  inset-block-start: 10px;
  inset-inline-end: 10px;
  color: #777;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`;

const ContainerModal = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    height: 100vh;
  }

  @media screen and (max-width: 480px) {
    height: 100vh;
  }
`;

const WindowDate = styled.div`
  background: transparent;
  display: flex;
  z-index: 10;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 420px) {
    overflow-y: none;
    overflow-y: none;
  }
`;

const Content = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 300px;
  height: 300px;
  padding: 10px 30px;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
`;

const Button = styled.button`
  background-color: #101010;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 5px;
  margin-top: 10px;
  border-radius: 20px;
  color: #fff;

  &:hover {
    background-color: #101010;
    color: #fff;
  }
`;
