import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDeleteForever } from "react-icons/md";
import {
  getStockHistoryAsync,
  setNewIncome,
  setNewInput,
  showHistory,
  showNewIncome,
  showNewInput,
} from "../../../../features/stockSlice";
import { formaterNumbertoPrice } from "../../../../utils/functions/reusableFunctions";
import Error from "../../../Error/Error";
import Table from "../../../Table/Table";
import { defaultModalState, defaultNewIncome } from "../../defaultValues";
import NewIncome from "../NewIncome";

function NewInputAccum({ setModal, closeModal, reentry = false }) {
  const dispatch = useDispatch();
  const inputs = useSelector(showHistory);
  const newInput = useSelector(showNewInput);

  const columns = [
    "Código",
    "Codigo de proveedor",
    "Referencia",
    "Cantidad",
    "Valor Unitario",
    "Costo Total",
    " "
  ];

  const reentryColumns = ["Código", "Referencia", "Cantidad", " "];

  useEffect(() => {
    if (reentry) {
      const reentries = inputs.filter(
        (input) => input.id.substr(0, 2) === "CR"
      );
      let lastId;
      let lastIndex;
      console.log(reentries);
      if (reentries.length > 0) {
        lastId = reentries[0].id;
        lastIndex = lastId.substr(2);
      } else {
        lastIndex = 0;
      }
      console.log(lastIndex);
      dispatch(
        setNewInput({
          ...newInput,
          id: `CR${lastIndex * 1 + 1}`,
          supplierId: "1",
          supplier: "Bodega",
          quotation: "No Aplica",
        })
      );
    }
  }, [reentry]);

  const addIncome = (newIncome) => {
    const { supplierCode, referenceId, quantity, value } = newIncome;

    if (
      !referenceId ||
      quantity === "" ||
      quantity === "0" ||
      (!reentry && (value === "" || value === "0"))
    ) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.partial.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      const incomes = [...newInput.incomes];
      if (supplierCode !== "") {
        incomes.push(newIncome);
      } else {
        const newIncomeAux = { ...newIncome };
        delete newIncomeAux.supplierCode;
        incomes.push(newIncomeAux);
      }

      console.log(incomes);
      dispatch(
        setNewInput({
          ...newInput,
          incomes,
        })
      );
      setModal((prev) => ({
        ...prev,
        crudForm: defaultModalState,
      }));
      dispatch(setNewIncome(defaultNewIncome));
    }
  };

  const openAddIncome = () => {
    setModal((prev) => ({
      ...prev,
      crudForm: {
        isActive: true,
        actionPrimary: "Guardar",
        handlePrimary: addIncome,
        partialSelector: showNewIncome,
        content: <NewIncome reentry={reentry} setModal={setModal} closeModal={closeModal} />,
        onClose: () => closeModal("crudForm", "newIncome"),
      },
    }));
  };

  const removeElement = (id) => {
    const selectedIncomes = [...newInput.incomes];
    const index = selectedIncomes.findIndex(item => item.referenceId === id);
    selectedIncomes.splice(index, 1);
    dispatch(setNewInput({
      ...newInput,
      incomes: selectedIncomes
    }));
  }

  return (
    <>
      <h2 className="modal__title modal__title--alt">
        {reentry ? "Nueva Reentrada" : "Nueva entrada de inventario"}
      </h2>
      <div className="modal__info">
        <div className="modal__accum modal__text">
          <p>
            <span>Código:</span> {newInput.id}
          </p>

          {!reentry && (
            <p>
              <span>Cotización:</span> {newInput.quotation}
            </p>
          )}

          <p>
            <span>Proveedor:</span> {newInput.supplier}
          </p>
          {!reentry && (
            <p>
              <span>Valor Total:</span>{" "}
              {formaterNumbertoPrice(
                newInput.incomes.reduce((prev, next) => {
                  return prev + next.quantity * next.value;
                }, 0)
              )}
            </p>
          )}

          <p>
            <span>Nro de elementos:</span> {newInput.incomes.length}
          </p>
        </div>
        <div className="modal__accumm--actions">
          <button className="modal__initAction" onClick={openAddIncome}>
            Añadir Elemento
          </button>
        </div>
      </div>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Elementos</h2>
        <Table alt="table" columns={reentry ? reentryColumns : columns}>
          <tbody className="table__body">
            {reentry
              ? newInput.incomes.map((income) => (
                  <tr key={income.referenceId}>
                    <td>{income.referenceId}</td>
                    <td>{income.referenceText}</td>
                    <td>{income.quantity * 1}</td>
                    <button onClick={() => removeElement(income.referenceId)} className="table__delete">
                      <MdOutlineDeleteForever />
                    </button>
                  </tr>
                ))
              : newInput.incomes.map((income) => (
                  <tr key={income.referenceId}>
                    <td>{income.referenceId}</td>
                    <td>{income.supplierCode}</td>
                    <td>{income.referenceText}</td>
                    <td>{income.quantity * 1}</td>
                    <td>{formaterNumbertoPrice(income.value)}</td>
                    <td>
                      {formaterNumbertoPrice(income.value * income.quantity)}
                    </td>
                    <button onClick={() => removeElement(income.referenceId)} className="table__delete">
                      <MdOutlineDeleteForever />
                    </button>
                  </tr>
                ))}
          </tbody>
        </Table>
        {newInput.incomes.length === 0 && (
          <p className="modal__text modal__empty">No hay elementos agregados</p>
        )}
      </div>
    </>
  );
}

export default NewInputAccum;
