import { createSlice, current } from "@reduxjs/toolkit";
import { LOCAL_NAME } from "../components/auth";
import axios from "axios";
import { logOut } from "../components/auth";
import serverUri from "../config/config";
const url = serverUri;

const initialState = {
  stock: [],
  pending: [],
  news: {},
  report: {},
};

export const dashboardSlide = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getStock: (state, action) => {
      state.stock = action.payload;
    },
    getPending: (state, action) => {
      state.pending = action.payload;
    },
    getNews: (state, action) => {
      state.news = action.payload;
    },
    getReport: (state, action) => {
      state.report = action.payload;
    },
  },
});

export const getDashboardStockAsync = (date) => async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem(LOCAL_NAME)).token;
    const response = await axios.get(`${url}/dashboard/stock?date=${date}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    dispatch(getStock(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const getDashboardPendingAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}/dashboard/pending`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getPending(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const getDashboardNewsAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}/dashboard/news`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getNews(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const getDashboardReportAsync = (date) => async (dispatch) => {
  try {
    const response = await axios.get(`${url}/dashboard/report?date=${date}`, {
      headers: {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
        }`,
      },
    });
    dispatch(getReport(response.data));
  } catch (err) {
    if (err.request.status === 401) {
      logOut(true);
    }
  }
};

export const modifyUserRole = (type, id, role) => async (dispatch) => {
  if (type === "patch") {
    try {
      const response = await axios.patch(`${url}/user/${id}`, role, {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      });
      return response;
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  } else if (type === "delete") {
    try {
      const response = await axios.delete(`${url}/user/${id}`, {
        headers: {
          Authorization: `bearer ${
            JSON.parse(localStorage.getItem(LOCAL_NAME))?.token
          }`,
        },
      });
      console.log(response);
    } catch (err) {
      if (err.request.status === 401) {
        logOut(true);
      }
    }
  } else {
    return alert("Type not found");
  }
};

export const { getStock, getPending, getNews, getReport } =
  dashboardSlide.actions;
export const showStock = (state) => state.dashboard.stock;
export const showPending = (state) => state.dashboard.pending;
export const showNews = (state) => state.dashboard.news;
export const showReport = (state) => state.dashboard.report;
export default dashboardSlide.reducer;
