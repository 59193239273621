import React from "react";
import { formaterNumbertoPrice } from "./../../../../utils/functions/reusableFunctions";
import { useDispatch } from "react-redux";
import Table from "../../../Table/Table";

function InputInfo({ input, setModal, closeModal }) {
  const supplier = input.incomes.find((income) => income.supplierId !== 1)
    .supplier.fullName;

  const reentry = input.id.substr(0, 2) === "CR";

  const columns = [
    "Código",
    "Código de Proveedor",
    "Referencia",
    "Cantidad",
    "Costo",
    "Costo Total",
  ];

  const reentryColumns = ["Código", "Referencia", "Cantidad"];

  return (
    <>
      <h2 className="modal__title modal__title--alt">Información</h2>
      <div className="modal__info">
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Código:</span> {input.id}
          </p>
          {!reentry && (
            <p>
              <span>Cotización:</span> {input.quotation}
            </p>
          )}
          {!reentry && (
            <p>
              <span>Proveedor:</span> {supplier}
            </p>
          )}

          <p>
            <span>Fecha:</span> {input.date}
          </p>
        </div>
        <div className="modal__accum modal__accum--only modal__text">
          <p>
            <span>Cantidad de elementos:</span> {input.incomesQuantity}
          </p>
          {!reentry && (
            <p>
              <span>Costo total:</span> {formaterNumbertoPrice(input.totalCost)}
            </p>
          )}

          <p>
            <span>Responsable:</span>{" "}
            {`${input.user.firstName} ${input.user.lastName}`}
          </p>
          {!reentry && (
            <p>
              <span>Factura:</span> {input.invoiceId}
            </p>
          )}
        </div>
      </div>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Elementos</h2>
        <Table alt="table" columns={!reentry ? columns : reentryColumns}>
          <tbody className="table__body">
            {!reentry
              ? input.incomes.map((income) => (
                  <tr key={income.referenceId}>
                    <td>{income.referenceId}</td>
                    <td>{income.supplierCode}</td>
                    <td>{income.description}</td>
                    <td>{income.quantity}</td>
                    <td>{formaterNumbertoPrice(income.value)}</td>
                    <td>
                      {formaterNumbertoPrice(income.value * income.quantity)}
                    </td>
                  </tr>
                ))
              : input.incomes.map((income) => (
                  <tr key={income.referenceId}>
                    <td>{income.referenceId}</td>
                    <td>{income.description}</td>
                    <td>{income.quantity}</td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default InputInfo;
