import React from "react";
import "./Form.css";

function Form({ children, row }) {
  return (
    <form
      className={row ? "form form--row" : "form form--column"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </form>
  );
}

export default Form;
