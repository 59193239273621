import React, { forwardRef, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../utils/functions/reusableFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputFilter,
  setInvoiceFilter,
  showInputFilter,
  showInvoiceFilter,
} from "../../../features/stockSlice";
import { useEffect } from "react";

function GeneralFilter({ closeModal, module }) {
  const dispatch = useDispatch();
  const inputFilter = useSelector(showInputFilter);
  const invoiceFilter = useSelector(showInvoiceFilter);
  const [anual, setAnual] = useState("Anual");
  const [mensual, setMensual] = useState("Mensual");
  const [diario, setDiario] = useState("Diario");

  useEffect(() => {
    if (module === "input" && inputFilter.date) {
      const number = inputFilter.date.split("/").length;
      if (number === 1) {
        setAnual(inputFilter.date);
      } else if (number === 2) {
        setMensual(inputFilter.date);
      } else if (number === 3) {
        setDiario(inputFilter.date);
      }
    }
  }, [inputFilter]);

  useEffect(() => {
    if (module === "invoice" && invoiceFilter.date) {
      const number = invoiceFilter.date.split("/").length;
      if (number === 1) {
        setAnual(invoiceFilter.date);
      } else if (number === 2) {
        setMensual(invoiceFilter.date);
      } else if (number === 3) {
        setDiario(invoiceFilter.date);
      }
    }
  }, [invoiceFilter]);

  const handleClick = (dateInput, type) => {
    if (module === "input") {
      dispatch(setInputFilter({ ...inputFilter, date: dateInput }));
    } else if (module === "invoice") {
      dispatch(setInvoiceFilter({ ...invoiceFilter, date: dateInput }));
    }
    switch (type) {
      case "anual":
        setAnual(dateInput);
        break;
      case "mensual":
        setMensual(dateInput);

        break;
      case "diario":
        setDiario(dateInput);

        break;
      default:
        break;
    }
    closeModal("partial");
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <>
      <Content>
        <DatePicker
          value={anual}
          onChange={(date) =>
            handleClick(formatDate(date).split("/")[2], "anual")
          }
          showYearPicker
          dateFormat="yyyy"
          placeholderText="Hola"
          customInput={<ExampleCustomInput />}
        />
        <DatePicker
          value={mensual}
          onChange={(date) =>
            handleClick(
              formatDate(date).split("/")[1] +
                "/" +
                formatDate(date).split("/")[2],
              "mensual"
            )
          }
          showMonthYearPicker
          dateFormat="MM/yyyy"
          customInput={<ExampleCustomInput />}
        />
        <DatePicker
          value={diario}
          onChange={(date) => handleClick(formatDate(date), "diario")}
          dateFormat="dd/MM/yyyy"
          customInput={<ExampleCustomInput />}
        />
      </Content>
    </>
  );
}

export default GeneralFilter;

const Content = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 300px;
  height: 300px;
  padding: 10px 30px;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
`;
