import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useSpring, animated } from "react-spring";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { getDashboardNewsAsync, modifyUserRole } from "../../../features/dashboardSlice";

const ModalRol = ({ user, showModal1, setShowModal1 }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal1 ? 1 : 0,
    transform: showModal1 ? `translateY(0%)` : `translateY(-100%)`,
  });

  const [size, setSize] = useState(false);
  const [currentRole, setCurrentRole] = useState("");

  const sizeFunction = (e) => {
    const { value } = e.target;
    // setSize((prev) => !prev);
    setCurrentRole(value);
  };

  const handleClickChange = () => {
    console.log(currentRole);
    if (currentRole.length && currentRole !== "select") {
      dispatch(modifyUserRole("patch", user.id, { rol: currentRole }));
      setTimeout(() => {
        dispatch(getDashboardNewsAsync());
      }, 800)
      closeModal();
    }
  };

  const closeModal = () => {
    setShowModal1(false);
  };

  return (
    <>
      {showModal1 ? (
        <ContainerModal ref={modalRef}>
          <animated.div style={animation}>
            <WindowRol showModal={showModal1}>
              <Content size={size} setSize={setSize}>
                <ButtonClose onClick={closeModal} />
                <ContainerRol size={size} setSize={setSize}>
                  <TitleRol>Elige el rol del usuario</TitleRol>
                  <div>
                    <Rol>Rol</Rol>
                    <RolDropDown name="role" onChange={sizeFunction}>
                      <option value="select">Seleccionar</option>
                      <option value="admin">Administrador</option>
                      <option value="manager">Gestor</option>
                      <option value="assistance">Asistente</option>
                    </RolDropDown>
                  </div>
                </ContainerRol>
                <ButtonContainer>
                  <Button onClick={handleClickChange}>Asignar</Button>
                  <Button onClick={closeModal}>Cancelar</Button>
                </ButtonContainer>
              </Content>
            </WindowRol>
          </animated.div>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default ModalRol;

const ContainerModal = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    height: 1113px;
  }

  @media screen and (max-width: 480px) {
    height: 100vh;
  }
`;

const WindowRol = styled.div`
  background: transparent;
  display: flex;
  z-index: 10;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 420px) {
    overflow-y: none;
    overflow-y: none;
  }
`;

const Content = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 375px;
  height: 225px;
  padding: 10px 16px;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  transition: ease-in-out;

  ${({ size }) => {
    if (size) {
      return css`
        transition: height 0.1s;
        height: 340px;
      `;
    }
  }}
`;

const ButtonClose = styled(IoMdClose)`
  width: 100%;
  font-size: 30px;
  justify-content: center;
`;

const ContainerRol = styled.div`
  background-color: #232c26;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  ${({ size }) => {
    if (size) {
      return css`
        transition: height 0.2s;
        height: 310px;
      `;
    }
  }}
`;

const TitleRol = styled.h1`
  text-align: center;
  margin: 15px 0px;
  font-size: 13px;
  color: #fff;
  font-weight: 700;
  align-items: stretch;
`;

const Rol = styled.p`
  width: 100%;
  text-align: start;
  margin-left: 20px;
  color: #fff;
`;

const RolDropDown = styled.select`
  display: inline-block;
  width: 90%;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 7px 10px;
  height: 42px;
  outline: 0;
  border: 0;
  background: #f0f0f0;
  color: #7b7b7b;
  font-size: 1em;
  color: #999;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  position: relative;

  option {
    background-color: #fff;
    color: black;
    border-radius: 10px;

    &::hover {
      color: #fff;
      background-color: #258d00;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  width: 100%;
  background-color: #258d00;
  margin: 9px 9px 0px 9px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
`;
