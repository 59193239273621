import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { MdOutlineWarningAmber } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useSpring, animated } from "react-spring";

const ModalStock = ({ data, showModalStock, setShowModalStock }) => {
  //data es un arreglo, donde la primera posicion indica el tipo de modal y la segunda un obj con la info del modal

  const name = data[0]
  const info = data[1]
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModalStock ? 1 : 0,
    transform: showModalStock ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = () => {
    setShowModalStock(false);
  };

  return (
    <>
      {showModalStock ? (
        <ContainerModal ref={modalRef}>
          <animated.div style={animation}>
            <WindowRol showModal={showModalStock}>
              <Content>
                <ButtonClose onClick={closeModal} />
                <TitleContainer>
                  <AlertIcon />
                  <Title>{name}</Title>
                </TitleContainer>
                <ContentContainer>
                  <SecondTitle>{info.description}</SecondTitle>
                  <Data>Referencia: {info.id}</Data>
                  <Data>
                    Magnitud: {info.magnitude + " " + info.unity.unity}
                  </Data>
                  <Data>Cantidad: {info.balance}</Data>
                  {name === "Outstock" ? (
                    <Data>Cantidad Minima: {info.minLimit}</Data>
                  ) : (
                    <Data>Cantidad Máxima: {info.maxLimit}</Data>
                  )}
                </ContentContainer>
              </Content>
            </WindowRol>
          </animated.div>
        </ContainerModal>
      ) : null}
    </>
  );
};

export default ModalStock;

const ContainerModal = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    height: 1113px;
  }

  @media screen and (max-width: 480px) {
    height: 100vh;
  }
`;

const WindowRol = styled.div`
  background: transparent;
  display: flex;
  z-index: 10;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 420px) {
    overflow-y: none;
  }
`;

const Content = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 323px;
  height: 175px;
  padding: 15px 20px;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  transition: ease-in-out;

  ${({ size }) => {
    if (size) {
      return css`
        transition: height 0.1s;
        height: 325px;
      `;
    }
  }}
`;

const ButtonClose = styled(IoMdClose)`
  position: absolute;
  font-size: 30px;
  top: 0;
  right: 0;
  margin-top: 11px;
  margin-right: 13px;
`;

const TitleContainer = styled.div`
  display: flex;
  color: #258d00;
`;

const AlertIcon = styled(MdOutlineWarningAmber)`
  font-size: 28px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  margin-left: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

const SecondTitle = styled.h2`
  font-size: 16px;
  color: #258d00;
  font-weight: 600;
`;

const Data = styled.p`
  font-size: 13.5px;
  font-weight: 600;
`;
