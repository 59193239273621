import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import { SideBar } from "../SideBar/SideBar";
import "./index.css";
import SupplierView from "./SupplierView";

function Index() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="stockContainer">
      <NavBar toggle={toggle} />
      <SideBar isOpen={isOpen} toggle={toggle} />
      <SupplierView></SupplierView>
    </div>
  );
}

export default Index;
