import React, { useEffect, useState } from "react";
import { MdOutlineDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockHistoryAsync,
  setInputChoice,
  setNewInvoice,
  showHistory,
  showInputChoice,
  showNewInvoice,
} from "../../../features/stockSlice";
import { formaterNumbertoPrice } from "../../../utils/functions/reusableFunctions";
import Error from "../../Error/Error";
import Form from "../../Form/Form";
import FormDrop from "../../Form/FormDrop/FormDrop";
import Table from "../../Table/Table";
import InputChoice from "./InputChoice";

function NewInvoiceAccum({ isNew = true, closeModal, setModal }) {
  const newInvoice = useSelector(showNewInvoice);
  const columns = [
    "Código",
    "Cotización",
    "Cantidad de Elementos",
    "Costo Total",
    "Fecha",
    " ",
  ];

  const dispatch = useDispatch();
  const inputs = useSelector(showHistory);
  const inputChoice = useSelector(showInputChoice);

  const addInput = (inputChoice, newInvoice) => {
    if (!inputChoice) {
      setModal((prev) => ({
        ...prev,
        decision: {
          isActive: true,
          content: <Error mssg={prev.crudForm.errors.required} />,
          actionPrimary: "Aceptar",
          handlePrimary: () => closeModal("decision"),
          onClose: () => closeModal("decision"),
        },
      }));
    } else {
      const inputsArray = [...newInvoice.inputs];
      inputsArray.push(inputChoice);
      dispatch(setNewInvoice({ ...newInvoice, inputs: inputsArray }));
      closeModal("crudForm", "addInput");
    }
  };

  const openAddInput = () => {
    dispatch(getStockHistoryAsync());
    setModal((prev) => ({
      ...prev,
      crudForm: {
        isActive: true,
        actionPrimary: "Agregar",
        handlePrimary: addInput,
        partialSelector: showInputChoice,
        asyncSelector: showNewInvoice,
        content: <InputChoice />,
        onClose: () => closeModal("crudForm", "addInput"),
        errors: {
          ...prev.crudForm.errors,
        },
      },
    }));
  };

  const removeElement = (id) => {
    const selectedInputs = [...newInvoice.inputs];
    const index = selectedInputs.findIndex((item) => item.id === id);
    selectedInputs.splice(index, 1);
    dispatch(
      setNewInvoice({
        ...newInvoice,
        inputs: selectedInputs,
      })
    );
  };

  return (
    <>
      <h2 className="modal__title modal__title--alt">
        {isNew ? "Nueva Factura" : "Editar Factura"}
      </h2>
      <div className="modal__info">
        <div className="modal__accum modal__text">
          <p>
            <span>Codigo: </span> {newInvoice.id}
          </p>
          <p>
            <span>Proveedor: </span>{" "}
            {isNew ? newInvoice.supplier : newInvoice.supplier.fullName}
          </p>
          {!isNew && (
            <p>
              <span>Fecha: </span> {newInvoice.date}
            </p>
          )}

          <p>
            <span>Cantidad de Remisiones: </span> {newInvoice.inputs.length}
          </p>
          <p>
            <span>Costo Total: </span>{" "}
            {formaterNumbertoPrice(
              newInvoice.inputs.reduce((prev, next) => {
                return prev + next.totalCost;
              }, 0)
            )}
          </p>
        </div>
        <div className="modal__accumm--actions">
          <button className="modal__initAction" onClick={openAddInput}>
            Añadir Remisión
          </button>
        </div>
      </div>
      <div className="modal__table">
        <h2 className="modal__title modal__title--alt">Remisiones</h2>
        <Table alt="table" columns={columns}>
          <tbody className="table__body">
            {newInvoice.inputs.map((input) => (
              <tr key={input.id}>
                <td>{input.id}</td>
                <td>{input.quotation}</td>
                <td>{input.incomesQuantity * 1}</td>
                <td>{formaterNumbertoPrice(input.totalCost)}</td>
                <td>{input.date}</td>
                <button
                  onClick={() => removeElement(input.id)}
                  className="table__delete"
                >
                  <MdOutlineDeleteForever />
                </button>
              </tr>
            ))}
          </tbody>
        </Table>
        {newInvoice.inputs.length === 0 && (
          <p className="modal__text modal__empty">
            No hay remisiones agregadas
          </p>
        )}
      </div>
    </>
  );
}

export default NewInvoiceAccum;
